import React, { useState, useEffect } from 'react';
import { Box, Typography, CircularProgress, RadioGroup, FormControlLabel, Radio, Card, CardContent, Grid, IconButton, Tooltip } from '@mui/material';
import { ArrowUpward, ArrowDownward, UnfoldMore } from '@mui/icons-material';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../firebase';
import { useSlateContext } from '../context/SlateContext';
import { useAuth } from '../context/AuthContext';
import SecurityTwoToneIcon from '@mui/icons-material/SecurityTwoTone';
import RocketLaunchTwoToneIcon from '@mui/icons-material/RocketLaunchTwoTone';
import EmojiEventsTwoToneIcon from '@mui/icons-material/EmojiEventsTwoTone';
import { ScatterChart, Scatter, XAxis, YAxis, ZAxis, Tooltip as RechartsTooltip, ResponsiveContainer, Label, Cell } from 'recharts';
import { alpha } from '@mui/material/styles';


interface StatsData {
    id: string;
    playerName?: string;
    Player?: string;
    teamAbbreviation?: string;
    'CashScore+': number;
    'UpsideScore+': number;
    'LeverageScore+': number;
    slate_identifier: number;
}

function PlayerStats() {
    const { isLoggedIn } = useAuth();
    const { slateId, sport, slateType } = useSlateContext();
    const [pitchers, setPitchers] = useState<StatsData[]>([]);
    const [players, setPlayers] = useState<StatsData[]>([]);
    const [stacks, setStacks] = useState<StatsData[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [selectedView, setSelectedView] = useState<'players' | 'stacks'>('players');
    const [sortConfig, setSortConfig] = useState<{ key: string; direction: 'asc' | 'desc' }>({ key: 'LeverageScore+', direction: 'desc' });

    useEffect(() => {
        if (isLoggedIn && slateId) {
            fetchData();
        } else {
            setLoading(false);
        }
    }, [isLoggedIn, slateId, sport, slateType]);

    const fetchData = async () => {
        setLoading(true);
        setError(null);
        try {
            let playersQuery, stacksQuery;

            if (slateType === 'Showdown') {
                playersQuery = query(
                    collection(db, 'pitchers'),
                    where('slate_identifier', '==', slateId),
                    where('sport', '==', sport)
                );
                stacksQuery = query(
                    collection(db, 'stacks'),
                    where('slate_identifier', '==', slateId),
                    where('sport', '==', sport)
                );
            } else if (sport === 'NFL') {
                playersQuery = query(
                    collection(db, 'pitchers'),
                    where('slate_identifier', '==', slateId),
                    where('sport', '==', sport),
                    where('position', '==', 'Offense-QB-1')
                );
                stacksQuery = query(
                    collection(db, 'stacks'),
                    where('slate_identifier', '==', slateId),
                    where('sport', '==', sport)
                );
            } else {
                playersQuery = query(
                    collection(db, 'pitchers'),
                    where('slate_identifier', '==', slateId),
                    where('sport', '==', sport)
                );
                stacksQuery = query(
                    collection(db, 'stacks'),
                    where('slate_identifier', '==', slateId),
                    where('sport', '==', sport)
                );
            }

            const [playersSnapshot, stacksSnapshot] = await Promise.all([
                getDocs(playersQuery),
                getDocs(stacksQuery)
            ]);

            const roundToOneDecimal = (num: number) => Math.round(num * 10) / 10;

            const filterValidData = (data: any, isStack: boolean): boolean => {
                if (slateType === 'Showdown' || !isStack) {
                    // For Showdown slates or player data, we require the Player field
                    return (
                        (data.Player && data.Player.trim() !== '') &&
                        typeof data['CashScore+'] === 'number' &&
                        typeof data['UpsideScore+'] === 'number' &&
                        typeof data['LeverageScore+'] === 'number'
                    );
                } else {
                    // For Classic slate stacks, we check for teamAbbreviation instead of Player
                    return (
                        (data.teamAbbreviation && data.teamAbbreviation.trim() !== '') &&
                        typeof data['CashScore+'] === 'number' &&
                        typeof data['UpsideScore+'] === 'number' &&
                        typeof data['LeverageScore+'] === 'number'
                    );
                }
            };

            const playersData: StatsData[] = playersSnapshot.docs
                .map(doc => {
                    const data = doc.data();
                    return {
                        id: doc.id,
                        playerName: data.playerName || data.Player,
                        Player: data.Player || data.playerName,
                        'CashScore+': roundToOneDecimal(data['CashScore+']),
                        'UpsideScore+': roundToOneDecimal(data['UpsideScore+']),
                        'LeverageScore+': roundToOneDecimal(data['LeverageScore+']),
                        slate_identifier: data.slate_identifier,
                        position: data.position,
                    };
                })
                .filter(data => filterValidData(data, false));

            const stacksData: StatsData[] = stacksSnapshot.docs
                .map(doc => {
                    const data = doc.data();
                    return {
                        id: doc.id,
                        playerName: data.Player || data.playerName,
                        Player: data.Player || data.playerName,
                        teamAbbreviation: data.teamAbbreviation,
                        'CashScore+': roundToOneDecimal(data['CashScore+']),
                        'UpsideScore+': roundToOneDecimal(data['UpsideScore+']),
                        'LeverageScore+': roundToOneDecimal(data['LeverageScore+']),
                        slate_identifier: data.slate_identifier,
                    };
                })
                .filter(data => filterValidData(data, true));

            setPlayers(playersData);
            setStacks(stacksData);
        } catch (error) {
            console.error('Error fetching data:', error);
            setError('An error occurred while fetching data. Please try again later.');
        }
        setLoading(false);
    };

    const sortData = (data: StatsData[]) => {
        return [...data].sort((a, b) => {
            const key = sortConfig.key as keyof StatsData;
            if (key in a && key in b) {
                const aValue = a[key];
                const bValue = b[key];
                if (typeof aValue === 'number' && typeof bValue === 'number') {
                    return sortConfig.direction === 'asc' ? aValue - bValue : bValue - aValue;
                }
            }
            return 0;
        });
    };

    const handleSort = (key: string) => {
        setSortConfig(prevConfig => ({
            key,
            direction: prevConfig.key === key && prevConfig.direction === 'asc' ? 'desc' : 'asc',
        }));
    };

    const getColorForScore = (score: number) => {
        if (score >= 110) return alpha('#4caf50', 0.2);   // Green with 20% opacity
        if (score >= 90) return alpha('#ffeb3b', 0.2);   // Yellow with 20% opacity
        return alpha('#f44336', 0.2);                     // Red with 20% opacity
    };

    const getColorForScorei = (score: number) => {
        if (score >= 110) return '#4caf50';   // Green with 20% opacity
        if (score >= 90) return '#ffeb3b';   // Yellow with 20% opacity
        return '#f44336';                     // Red with 20% opacity
    };

    const renderHeaderCard = () => (
        <Card elevation={3} sx={{ mb: 1 }}>
            <CardContent sx={{ py: 1 }}>
                <Grid container alignItems="center">
                    <Grid item xs={2.75}>
                        <Typography>
                            {slateType === 'Showdown'
                                ? (selectedView === 'players' ? 'FLEX' : 'Captain')
                                : (selectedView === 'players' ? (sport === 'NFL' ? 'QB' : 'Pitcher') : 'Stack')}
                        </Typography>
                    </Grid>
                    {['CashScore+', 'UpsideScore+', 'LeverageScore+'].map((score) => (
                        <Grid item xs={3} key={score}>
                            <Box display="flex" alignItems="center">
                                <Typography sx={{ mr: .1 }}>{score}</Typography>
                                <IconButton size="small" onClick={() => handleSort(score)}>
                                    {sortConfig.key === score
                                        ? (sortConfig.direction === 'asc' ? <ArrowUpward fontSize="small" /> : <ArrowDownward fontSize="small" />)
                                        : <UnfoldMore fontSize="small" />
                                    }
                                </IconButton>
                            </Box>
                        </Grid>
                    ))}
                </Grid>
            </CardContent>
        </Card>
    );

    const renderDataCards = () => {
        const data = selectedView === 'players' ? players : stacks;
        const sortedData = sortData(data);

        return sortedData.map((item) => (
            <Card key={item.id} elevation={3} sx={{ mb: 1 }}>
                <CardContent sx={{ py: 1 }}>
                    <Grid container alignItems="center">
                        <Grid item xs={2.75}>
                            <Typography>
                                {slateType === 'Showdown'
                                    ? (item.Player || item.playerName || 'Unknown Player')
                                    : (selectedView === 'players'
                                        ? (item.playerName || item.Player || 'Unknown Player')
                                        : (item.teamAbbreviation || 'Unknown Team'))}
                            </Typography>
                        </Grid>
                        {['CashScore+', 'UpsideScore+', 'LeverageScore+'].map((score) => (
                            <Grid item xs={3} key={score}>
                                <Box display="flex" alignItems="center" sx={{ ml: 1 }}>
                                    {score === 'CashScore+' && <SecurityTwoToneIcon fontSize="small" sx={{ mr: 0.5, color: getColorForScorei(item[score as keyof StatsData] as number) }} />}
                                    {score === 'UpsideScore+' && <RocketLaunchTwoToneIcon fontSize="small" sx={{ mr: 0.5, color: getColorForScorei(item[score as keyof StatsData] as number) }} />}
                                    {score === 'LeverageScore+' && <EmojiEventsTwoToneIcon fontSize="small" sx={{ mr: 0.5, color: getColorForScorei(item[score as keyof StatsData] as number) }} />}
                                    <Typography sx={{
                                        backgroundColor: getColorForScore(item[score as keyof StatsData] as number),
                                        color: '#000',
                                        borderRadius: '4px',
                                        px: 0.5,
                                        py: 0.15
                                    }}>
                                        {(item[score as keyof StatsData] as number).toFixed(1)}
                                    </Typography>
                                </Box>
                            </Grid>
                        ))}
                    </Grid>
                </CardContent>
            </Card>
        ));
    };

    const renderBubbleChart = (data: StatsData[]) => {
        const scaledData = data.map(d => ({
            ...d,
            scaledLeverageScore: (d['LeverageScore+'] - 80) * 5
        }));

        const colors = ['#2E4053', '#566573', '#1ABC9C', '#2980B9', '#8E44AD', '#C0392B', '#D35400', '#27AE60', '#F39C12', '#34495E', '#16A085', '#2980B9', '#E74C3C', '#F1C40F', '#E67E22', '#B03A2E'];

        return (
            <ResponsiveContainer width="100%" height={400}>
                <ScatterChart margin={{ top: 20, right: 20, bottom: 20, left: 20 }}>
                    <XAxis
                        type="number"
                        dataKey="CashScore+"
                        name="Cash Score+"
                        unit="+"
                        domain={[60, 140]}
                    >
                        <Label value="Cash Score+" position="insideBottom" offset={-10} />
                    </XAxis>
                    <YAxis
                        type="number"
                        dataKey="UpsideScore+"
                        name="Upside Score+"
                        unit="+"
                        domain={[60, 140]}
                    >
                        <Label value="Upside Score+" angle={-90} position="insideLeft" offset={10} />
                    </YAxis>
                    <ZAxis type="number" dataKey="scaledLeverageScore" range={[50, 2000]} />
                    <RechartsTooltip
                        cursor={{ strokeDasharray: '3 3' }}
                        content={({ active, payload }) => {
                            if (active && payload && payload.length) {
                                const { Player, playerName, teamAbbreviation, 'CashScore+': cashScore, 'UpsideScore+': upsideScore, 'LeverageScore+': leverageScore } = payload[0].payload;
                                let displayName;
                                let displayLabel;

                                if (slateType === 'Showdown') {
                                    displayName = Player || playerName;
                                    displayLabel = 'Player';
                                } else {
                                    displayName = selectedView === 'players' ? (playerName || Player) : teamAbbreviation;
                                    displayLabel = selectedView === 'players' ? 'Player' : 'Team';
                                }

                                return (
                                    <div style={{ backgroundColor: 'white', padding: '5px', border: '1px solid #ccc' }}>
                                        <p>{`${displayLabel}: ${displayName}`}</p>
                                        <p>{`Cash Score+: ${cashScore}`}</p>
                                        <p>{`Upside Score+: ${upsideScore}`}</p>
                                        <p>{`Leverage Score+: ${leverageScore}`}</p>
                                    </div>
                                );
                            }
                            return null;
                        }}
                    />
                    <Scatter data={scaledData} opacity={0.7}>
                        {scaledData.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                        ))}
                    </Scatter>
                </ScatterChart>
            </ResponsiveContainer>
        );
    };

    if (!isLoggedIn) {
        return (
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: 'calc(100vh - 64px)',
                p: 3,
                textAlign: 'center'
            }}>
                <Typography variant="h4" gutterBottom>
                    Welcome to the DFS OS
                </Typography>
                <Typography variant="h5" color="error" sx={{ mb: 2 }}>
                    You need to be logged in to use this feature
                </Typography>
                <Typography variant="body1">
                    Please use the login button in the top right corner to access all features.
                </Typography>
                <Typography variant="body2" sx={{ mt: 2, fontStyle: 'italic' }}>
                    If you don't have an account yet, you can sign up for free!
                </Typography>
            </Box>
        );
    }

    if (!slateId) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <Typography variant="h5">Please select a Slate to view Data.</Typography>
            </Box>
        );
    }

    return (
        <Box sx={{ p: 1, maxWidth: '100%', overflow: 'hidden', marginTop: 12 }}>
            <Typography variant="h4" component="h1" gutterBottom sx={{ mb: 2 }}>
                Player Stats and Stacks
            </Typography>
            <Card elevation={3} sx={{ mb: 1 }}>
                <CardContent sx={{ py: 1 }}>
                    <RadioGroup
                        row
                        value={selectedView}
                        onChange={(e) => {
                            setSelectedView(e.target.value as 'players' | 'stacks');
                            setSortConfig({ key: 'LeverageScore+', direction: 'desc' });
                        }}
                    >
                        <FormControlLabel
                            value="players"
                            control={<Radio />}
                            label={slateType === 'Showdown' ? 'FLEX' : (sport === 'NFL' ? 'QBs' : 'Pitchers')}
                        />
                        <FormControlLabel
                            value="stacks"
                            control={<Radio />}
                            label={slateType === 'Showdown' ? 'Captains' : 'Stacks'}
                        />
                    </RadioGroup>
                </CardContent>
            </Card>
            {loading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
                    <CircularProgress />
                </Box>
            ) : error ? (
                <Typography color="error">{error}</Typography>
            ) : (
                <Grid container spacing={1}>
                    <Grid item xs={12} md={5}>
                        <Box sx={{ maxHeight: 'calc(100vh - 250px)', overflowY: 'auto', pr: 1 }}>
                            {renderHeaderCard()}
                            {renderDataCards()}
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={7}>
                        <Card elevation={3}>
                            <CardContent>
                                <Typography variant="h6" gutterBottom>Score Distribution</Typography>
                                {renderBubbleChart(selectedView === 'players' ? players : stacks)}
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            )}
        </Box>
    );
}

export default PlayerStats;