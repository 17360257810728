import React, { createContext, useContext, useState } from 'react';

interface PositionLimits {
    ALL: number;
    P: number;
    C: number;
    '1B': number;
    '2B': number;
    '3B': number;
    SS: number;
    OF: number;
    QB: number;
    RB: number;
    WR: number;
    TE: number;
    DST: number;
    [key: string]: number;
}

interface SettingsContextType {
    settings: {
        // numberOfLineups: number;
        salaryRange: [number, number];
        totalOwnership: [number, number];
        hittersVsPitcher: number;
        rbDstStack: boolean;
        maxPlayersPerTeam: number;
        flexPositions: {
            WR: boolean;
            RB: boolean;
            TE: boolean;
        };
        forceLowOwnedPlayers: boolean;
        numberOfLowOwnedPlayers: number;
        lowOwnThreshold: number;
        useRandomness: boolean;
        randomnessPercentage: number;
        usePlayerDistributions: boolean;
        useCorrelatedDistributions: boolean;
        positionLimits: PositionLimits;
        showdownCombinations: { [key: string]: boolean };
    };
    updateSettings: (newSettings: Partial<SettingsContextType['settings']>) => void;
}

const defaultSettings: SettingsContextType['settings'] = {
    // numberOfLineups: 10,
    salaryRange: [30000, 50000],
    totalOwnership: [50, 150],
    hittersVsPitcher: 0,
    rbDstStack: false,
    maxPlayersPerTeam: 4,
    flexPositions: {
        WR: true,
        RB: true,
        TE: true,
    },
    forceLowOwnedPlayers: false,
    numberOfLowOwnedPlayers: 0,
    lowOwnThreshold: 10,
    useRandomness: true,
    randomnessPercentage: 20,
    usePlayerDistributions: false,
    useCorrelatedDistributions: false,
    positionLimits: {
        ALL: 100,
        P: 100,
        C: 100,
        '1B': 100,
        '2B': 100,
        '3B': 100,
        SS: 100,
        OF: 100,
        QB: 100,
        RB: 100,
        WR: 100,
        TE: 100,
        DST: 100,
    },
    showdownCombinations: {},
};

const SettingsContext = createContext<SettingsContextType | undefined>(undefined);

export const SettingsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [settings, setSettings] = useState(defaultSettings);

    const updateSettings = (newSettings: Partial<SettingsContextType['settings']>) => {
        setSettings(prevSettings => {
            const updatedSettings = { ...prevSettings, ...newSettings };
            if (newSettings.positionLimits) {
                updatedSettings.positionLimits = {
                    ...prevSettings.positionLimits,
                    ...newSettings.positionLimits
                };
            }
            return updatedSettings;
        });
    };

    return (
        <SettingsContext.Provider value={{ settings, updateSettings }}>
            {children}
        </SettingsContext.Provider>
    );
};

export const useSettings = () => {
    const context = useContext(SettingsContext);
    if (context === undefined) {
        throw new Error('useSettings must be used within a SettingsProvider');
    }
    return context;
};