import React, { useState, useEffect } from 'react';
import { Box, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../firebase';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { format, parseISO, isValid, isBefore } from 'date-fns';

interface Slate {
    id: string;
    forDate: string;
    slate_identifier: number;
    type: string;
    sport: string;
}

interface Game {
    awayTeamAbbreviation: string;
    homeTeamAbbreviation: string;
    startTime: string;
    slate_identifier: number;
}

interface SlateFiltersProps {
    onFilterChange: (sport: string, date: string | null, slateId: number | null, slateType: 'Classic' | 'Showdown' | null) => void;
}

const SlateFilters: React.FC<SlateFiltersProps> = ({ onFilterChange }) => {
    const [sport, setSport] = useState('MLB');
    const [date, setDate] = useState<Date | null>(null);
    const [slateId, setSlateId] = useState<number | null>(null);
    const [slateType, setSlateType] = useState<string>('Classic');
    const [availableDates, setAvailableDates] = useState<Date[]>([]);
    const [availableSlates, setAvailableSlates] = useState<Slate[]>([]);
    const [availableSports, setAvailableSports] = useState<string[]>([]);
    const [games, setGames] = useState<Game[]>([]);
    const minSelectableDate = new Date('2024-09-03');

    useEffect(() => {
        fetchAvailableSports();
    }, []);

    useEffect(() => {
        if (sport) {
            fetchAvailableDates(sport);
        }
    }, [sport]);

    useEffect(() => {
        if (sport && date) {
            fetchAvailableSlates(sport, date);
        }
    }, [sport, date]);

    const fetchAvailableSports = async () => {
        const slatesCollection = collection(db, 'slates');
        const slatesSnapshot = await getDocs(slatesCollection);
        const sports = new Set<string>();
        slatesSnapshot.forEach(doc => {
            const sportField = doc.data().sport;
            if (sportField) sports.add(sportField);
        });
        setAvailableSports(Array.from(sports));
    };

    const fetchAvailableDates = async (selectedSport: string) => {
        const slatesCollection = collection(db, 'slates');
        const sportQuery = query(slatesCollection, where('sport', '==', selectedSport));
        const slatesSnapshot = await getDocs(sportQuery);
        const dates = slatesSnapshot.docs
            .map(doc => {
                const forDate = doc.data().forDate;
                const parsedDate = parseISO(forDate);
                return isValid(parsedDate) ? parsedDate : null;
            })
            .filter((date): date is Date => date !== null);

        setAvailableDates(Array.from(new Set(dates)).sort((a, b) => a.getTime() - b.getTime()));
    };

    const fetchAvailableSlates = async (selectedSport: string, selectedDate: Date) => {
        const slatesCollection = collection(db, 'slates');
        const q = query(
            slatesCollection,
            where('sport', '==', selectedSport),
            where('forDate', '==', selectedDate.toISOString().split('T')[0])
        );
        const slatesSnapshot = await getDocs(q);
        const slates = slatesSnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
        } as Slate)).filter(slate => slate.type && typeof slate.type === 'string');
        setAvailableSlates(slates);
    };

    const fetchGames = async (slateIdentifier: number): Promise<Game[]> => {
        const gamesCollection = collection(db, 'slates_games');
        const q = query(gamesCollection, where('slate_identifier', '==', slateIdentifier));
        const gamesSnapshot = await getDocs(q);
        return gamesSnapshot.docs.map(doc => doc.data() as Game);
    };

    const handleSportChange = (event: SelectChangeEvent<string>) => {
        const newSport = event.target.value;
        setSport(newSport);
        setDate(null);
        setSlateId(null);
        setSlateType('Classic');
        onFilterChange(newSport, null, null, 'Classic');
    };

    const handleDateChange = (newDate: Date | null) => {
        setDate(newDate);
        setSlateId(null);
        setSlateType('Classic');
        onFilterChange(sport, newDate ? newDate.toISOString().split('T')[0] : null, null, 'Classic');
    };

    const handleSlateChange = (event: SelectChangeEvent<number>) => {
        const newSlateId = event.target.value as number;
        const selectedSlate = availableSlates.find(slate => slate.slate_identifier === newSlateId);
        const newSlateType = selectedSlate?.type.startsWith('Showdown') ? 'Showdown' : 'Classic';
        setSlateId(newSlateId);
        setSlateType(newSlateType);
        onFilterChange(sport, date ? date.toISOString().split('T')[0] : null, newSlateId, newSlateType);
    };

    const formatTime = (timeString: string) => {
        const date = new Date(timeString);
        return date.toLocaleTimeString('en-US', {
            hour: '2-digit',
            minute: '2-digit',
            hour12: true,
            timeZone: 'America/New_York'
        });
    };

    const getSlateDisplayText = (slate: Slate) => {
        if (slate.type.startsWith('Showdown')) {
            const game = games.find(g => g.slate_identifier === slate.slate_identifier);
            if (game) {
                return `${slate.type} - ${game.awayTeamAbbreviation} @ ${game.homeTeamAbbreviation}`;
            }
        }
        return slate.type;
    };


    const filteredAndSortedSlates = availableSlates
        .filter(slate => typeof slate.type === 'string' && (slate.type.startsWith('Classic') || slate.type.startsWith('Showdown')))
        .sort((a, b) => a.type.localeCompare(b.type));

    const allSports = ['MLB', 'NFL', 'NBA', 'PGA'];

    return (
        <Box display="flex" alignItems="center" gap={1}>
            <FormControl variant="outlined" size="small" sx={{ minWidth: 80 }}>
                <InputLabel sx={{ color: 'black', fontSize: '0.8rem' }}>Sport</InputLabel>
                <Select
                    value={sport}
                    onChange={handleSportChange}
                    label="Sport"
                    sx={{ color: 'black', '.MuiOutlinedInput-notchedOutline': { borderColor: 'black' }, fontSize: '0.8rem' }}
                >
                    {allSports.map((sportOption) => (
                        <MenuItem
                            key={sportOption}
                            value={sportOption}
                            disabled={!availableSports.includes(sportOption)}
                            sx={{
                                color: availableSports.includes(sportOption) ? 'inherit' : 'grey',
                            }}
                        >
                            {sportOption}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                    label="Date"
                    value={date}
                    onChange={handleDateChange}
                    shouldDisableDate={(date) => {
                        if (isBefore(date, minSelectableDate)) {
                            return true;
                        }
                        const formattedDate = format(date, 'yyyy-MM-dd');
                        return !availableDates.some(availableDate =>
                            format(availableDate, 'yyyy-MM-dd') === formattedDate
                        );
                    }}
                    minDate={minSelectableDate}
                    slotProps={{
                        textField: {
                            size: "small",
                            sx: {
                                minWidth: 100,
                                input: { color: 'black' },
                                label: { color: 'black' },
                                '.MuiOutlinedInput-notchedOutline': { borderColor: 'black' }
                            }
                        }
                    }}
                />
            </LocalizationProvider>

            <FormControl variant="outlined" size="small" sx={{ minWidth: 100 }}>
                <InputLabel sx={{ color: 'black', fontSize: '0.8rem' }}>Slate</InputLabel>
                <Select
                    value={slateId || ''}
                    onChange={handleSlateChange}
                    label="Slate"
                    disabled={!date}
                    sx={{ color: 'black', '.MuiOutlinedInput-notchedOutline': { borderColor: 'black' }, fontSize: '0.8rem' }}
                >
                    {filteredAndSortedSlates
                        .filter(slate => sport === 'NFL' || !slate.type.startsWith('Showdown'))
                        .map((slate) => (
                            <MenuItem key={slate.id} value={slate.slate_identifier}>
                                {slate.type}
                            </MenuItem>
                        ))}
                </Select>
            </FormControl>
        </Box>
    );
};

export default SlateFilters;