import React, { useState, useEffect, useRef } from 'react';
import { Box, Typography, Radio, RadioGroup, FormControlLabel, FormControl, FormLabel, Paper, Button, Alert, Tooltip, Card, CardContent, Grid, Divider } from '@mui/material';
import { CloudUpload, Help, GetApp } from '@mui/icons-material';
import { useAuth } from '../context/AuthContext';
import { useSlateContext } from '../context/SlateContext';
import { collection, query, where, getDocs, orderBy, limit } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { db } from '../firebase';
import Papa from 'papaparse';
import { SimulationResults } from '../types';
import Skeleton from '@mui/material/Skeleton';
import StackFrequencyTable from './StackFrequencyTable';
import LineupResultsTable from './LineupResultsTable';

interface Player {
    id: string;
    [key: string]: any;
}

interface Lineup {
    lineupId: string;
    totalProjection: number;
    totalSalary: number;
    win_pct: number;
    top1p: number;
    top10p: number;
    cash_pct: number;
    roi: number;
    players: Player[];
}

interface ContestSimulatorProps {
    results: SimulationResults | null;
    updateResults: (results: SimulationResults) => void;
}

function ContestSimulator({ results, updateResults }: ContestSimulatorProps) {
    const [contestSize, setContestSize] = useState<string>('');
    const [simOption, setSimOption] = useState<string>('');
    const { isLoggedIn, user } = useAuth();
    const { sport, date, slateId, slateType } = useSlateContext();
    const [players, setPlayers] = useState<Player[]>([]);
    const [lineups, setLineups] = useState<Lineup[]>([]);
    const [error, setError] = useState<string | null>(null);
    const [uploadedLineups, setUploadedLineups] = useState<Lineup[]>([]);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [totalLineups, setTotalLineups] = useState<number>(0);
    const [jobId, setJobId] = useState<string | null>(null);
    const [lineupResults, setLineupResults] = useState<any[]>([]);
    const [stackFrequencySummary, setStackFrequencySummary] = useState<any[]>([]);
    const [showResults, setShowResults] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const isShowdown = slateType === 'Showdown';


    useEffect(() => {
        if (isLoggedIn && user && slateId) {
            fetchPlayersAndLineups();
        } else if (!isLoggedIn) {
            setError("Please log in to use the Contest Simulator.");
        } else if (!slateId) {
            setError("Please select a slate before running the simulation.");
        }
    }, [isLoggedIn, user, slateId]);

    useEffect(() => {
        // Update totalLineups whenever lineups or uploadedLineups change
        setTotalLineups(lineups.length + uploadedLineups.length);
    }, [lineups, uploadedLineups]);

    const fetchPlayersAndLineups = async () => {
        try {
            setError(null);
            // Fetch players (keep this part as is)
            const playersQuery = query(collection(db, 'data_players'), where('slate_identifier', '==', slateId));
            const playerSnapshot = await getDocs(playersQuery);
            const playerList: Player[] = playerSnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            setPlayers(playerList);

            // Fetch the most recent job_id for the current user and slate
            if (user) {
                const jobQuery = query(
                    collection(db, 'user_jobs'),
                    where('userId', '==', user.uid),
                    where('slateId', '==', slateId),
                    orderBy('createdAt', 'desc'),
                    limit(1)
                );
                const jobSnapshot = await getDocs(jobQuery);
                if (!jobSnapshot.empty) {
                    const mostRecentJob = jobSnapshot.docs[0].data();
                    setJobId(mostRecentJob.jobId);
                    // Fetch lineups for this job_id
                    await fetchLineupsForJob(mostRecentJob.jobId);
                } else {
                    setError("No lineups found for this slate. Please build lineups first.");
                }
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            setError("An error occurred while fetching data. Please try again.");
        }
    };

    const convertToCSV = (data: any[]): string => {
        const mlbPositions = ['P1', 'P2', 'C1', '1B1', '2B1', '3B1', 'SS1', 'OF1', 'OF2', 'OF3'];
        const nflPositions = ['QB1', 'RB1', 'RB2', 'WR1', 'WR2', 'WR3', 'TE1', 'FLEX', 'DST1'];
        const showdownPositions = ['CPT1', 'FLEX1', 'FLEX2', 'FLEX3', 'FLEX4', 'FLEX5'];

        let positions;
        if (isShowdown) {
            positions = showdownPositions;
        } else if (sport === 'NFL') {
            positions = nflPositions;
        } else {
            positions = mlbPositions;
        }

        const columnOrder = ['lineup_id', 'UEscore', 'avgrnk', 'cash_pct', 'roi', 'win_pct', ...positions];

        const headers = columnOrder.join(',');
        const rows = data.map(row =>
            columnOrder.map(col => {
                const value = row[col];
                return typeof value === 'string' ? `"${value}"` : value;
            }).join(',')
        );
        return [headers, ...rows].join('\n');
    };

    const handleDownloadResults = () => {
        if (lineupResults.length > 0) {
            const csv = convertToCSV(lineupResults);
            const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
            const link = document.createElement('a');
            if (link.download !== undefined) {
                const url = URL.createObjectURL(blob);
                link.setAttribute('href', url);
                link.setAttribute('download', 'contest_simulation_results.csv');
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    };

    const downloadCSV = (data: any[], filename: string) => {
        const csv = convertToCSV(data);
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        if (link.download !== undefined) {
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', filename);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };

    const convertToCSVlu = (lineups: Lineup[]): string => {
        const columnOrder = ['lineup_id', 'UEscore', 'avgrnk', 'cash_pct', 'roi', 'win_pct', 'P1', 'P2', 'C1', '1B1', '2B1', '3B1', 'SS1', 'OF1', 'OF2', 'OF3'];

        const headers = [
            'Lineup ID',
            'Total Projection',
            'Total Salary',
            'Win %',
            'Top 1%',
            'Top 10%',
            'Cash %',
            'ROI',
            ...columnOrder
        ];
        const csvRows = [headers.join(',')];

        lineups.forEach(lineup => {
            const row = [
                lineup.lineupId,
                lineup.totalProjection.toFixed(2),
                lineup.totalSalary,
                (lineup.win_pct).toFixed(2) + '%',
                (lineup.top1p).toFixed(2) + '%',
                lineup.top10p.toFixed(2) + '%',
                (lineup.cash_pct).toFixed(2) + '%',
                (lineup.roi).toFixed(2) + '%',
                ...columnOrder.map((pos, index) => {
                    const player = lineup.players.find(p => p.position === pos &&
                        lineup.players.filter(op => op.position === pos).indexOf(p) === columnOrder.slice(0, index + 1).filter(p => p === pos).length - 1);
                    return player ? `${player.playerId} (${player.projection.toFixed(2)})` : '';
                })
            ];
            csvRows.push(row.join(','));
        });

        return csvRows.join('\n');
    };

    const downloadCSVlu = () => {
        const csv = convertToCSVlu(lineups);
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        if (link.download !== undefined) {
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', 'contest_simulation_results.csv');
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };

    // const handleDownloadResults = () => {
    //     if (lineupResults.length > 0) {
    //         downloadCSV(lineupResults, 'contest_simulation_results.csv');
    //     }
    // };

    const fetchLineupsForJob = async (jobId: string) => {
        if (!user) {
            console.error('User not authenticated');
            return;
        }

        const lineupsQuery = query(
            collection(db, 'lineups'),
            where('userId', '==', user.uid),
            where('jobId', '==', jobId)
        );
        const lineupsSnapshot = await getDocs(lineupsQuery);
        const fetchedLineups = lineupsSnapshot.docs.map(doc => doc.data() as Lineup);
        setLineups(fetchedLineups);
    };


    const handleRunSim = async () => {
        if (!user) {
            setError("You must be logged in to run the simulation.");
            return;
        }

        setIsLoading(true);
        setShowResults(false);

        let lineupsToUse: Lineup[] = [];

        if (uploadedLineups.length > 0) {
            lineupsToUse = uploadedLineups;
        } else if (lineups.length > 0) {
            lineupsToUse = lineups;
        }

        if (lineupsToUse.length === 0) {
            setError("No lineups found for the selected slate. Please build lineups or upload a CSV file before running the simulation.");
            return;
        }

        try {
            setError(null);
            const auth = getAuth();
            const user = auth.currentUser;

            if (!user) {
                throw new Error('User not authenticated');
            }

            const idToken = await user.getIdToken();

            const requestBody = {
                players: players,
                lineups: lineupsToUse,
                slateId: slateId,
                contestSize: contestSize,
                simOption: simOption,
                userId: user.uid,
            };

            console.log('API Request Body:', JSON.stringify(requestBody, null, 2));

            // Choose the API URL based on the sport
            let apiUrl = '';
            // if (sport === 'NFL') {
            //     apiUrl = 'https://ue-nfl-sim-450591700064.us-central1.run.app/submit_job'; // NFL
            // } else {
            //     apiUrl = 'https://ue-mlb-sim-450591700064.us-central1.run.app/submit_job'; // MLB
            // }
            if (isShowdown) {
                apiUrl = 'https://ue-show-sim-450591700064.us-central1.run.app/submit_job'; // New Showdown URL
            } else if (sport === 'NFL') {
                apiUrl = 'https://ue-nfl-sim-450591700064.us-central1.run.app/submit_job';
            } else {
                apiUrl = 'https://ue-mlb-sim-450591700064.us-central1.run.app/submit_job';
            }

            const response = await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${idToken}`
                },
                body: JSON.stringify(requestBody),
            });

            if (!response.ok) {
                throw new Error('Something broke, please try again. Contact us if the issue persists.');
            }

            const data = await response.json();
            const processedLineups = data.lineups.map((lineup: any, index: number) => ({
                ...lineup,
                id: lineup.lineup_id || `lineup-${index}`, // Use lineup_id if available, otherwise create a unique id
            }));
            updateResults({
                ...data,
                lineups: processedLineups,
            });
            setLineupResults(processedLineups);
            setStackFrequencySummary(data.stack_frequency_summary);
            setShowResults(true);
            setLineups(processedLineups);
        } catch (error) {
            console.error('Error:', error);
            setError("An error occurred while running the simulation. Please try again.");
        } finally {
            setIsLoading(false);
        }
    };

    const MAX_LINEUPS = 500;

    const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            if (file.type !== 'text/csv') {
                setError("Please upload a CSV file.");
                return;
            }

            Papa.parse(file, {
                complete: (results) => {
                    const parsedLineups = results.data.map((row: any, index: number) => ({
                        id: `uploaded-${index}`,
                        ...row
                    }));

                    if (parsedLineups.length > MAX_LINEUPS) {
                        setError(`CSV contains more than ${MAX_LINEUPS} lineups. Only the first ${MAX_LINEUPS} will be used.`);
                        setUploadedLineups(parsedLineups.slice(0, MAX_LINEUPS));
                    } else {
                        setUploadedLineups(parsedLineups);
                        setError(null);
                    }

                    setTotalLineups(Math.min(parsedLineups.length, MAX_LINEUPS));
                },
                header: true,
                error: (error) => {
                    console.error('CSV parsing error:', error);
                    setError("Error parsing CSV file. Please check the file format.");
                }
            });
        }
    };

    const triggerFileUpload = () => {
        fileInputRef.current?.click();
    };

    if (!isLoggedIn) {
        return (
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: 'calc(100vh - 64px)', // Adjust this if your app bar height is different
                p: 3,
                textAlign: 'center'
            }}>
                <Typography variant="h4" gutterBottom>
                    Welcome to the DFS OS
                </Typography>
                <Typography variant="h5" color="error" sx={{ mb: 2 }}>
                    You need to be logged in to use this feature
                </Typography>
                <Typography variant="body1">
                    Please use the login button in the top right corner to access all features.
                </Typography>
                <Typography variant="body2" sx={{ mt: 2, fontStyle: 'italic' }}>
                    If you don't have an account yet, you can sign up for free!
                </Typography>
            </Box>
        );
    }

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, p: 12 }}>
            {error && <Alert severity="error" sx={{ width: '100%' }}>{error}</Alert>}
            <Card elevation={3}>
                <CardContent>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <FormControl component="fieldset" sx={{ width: '100%' }}>
                                <FormLabel component="legend">Contest Size</FormLabel>
                                <RadioGroup
                                    value={contestSize}
                                    onChange={(e) => setContestSize(e.target.value)}
                                >
                                    <FormControlLabel value="small" control={<Radio />} label="Small Field: < 1,000 entries" />
                                    <FormControlLabel value="medium" control={<Radio />} label="Medium Field: 5,000 entries" />
                                    <FormControlLabel value="large" control={<Radio />} label="Large Field" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl component="fieldset" sx={{ width: '100%' }}>
                                <FormLabel component="legend">Simulation Options</FormLabel>
                                <RadioGroup
                                    value={simOption}
                                    onChange={(e) => setSimOption(e.target.value)}
                                >
                                    <FormControlLabel value="randomness" control={<Radio />} label="Use Randomness" />
                                    <FormControlLabel value="playerDist" control={<Radio />} label="Use Player Distributions" />
                                    <FormControlLabel value="corrDist" control={<Radio />} label="Use Correlated Distributions" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Divider sx={{ my: 2 }} />
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Typography
                            variant="subtitle1"
                            color="text.secondary"
                            sx={{ marginRight: 2 }} // or sx={{ mr: 2 }}
                        >
                            {totalLineups > 0
                                ? `${totalLineups} Lineups ready to be simulated`
                                : "No lineups available. Please build or upload lineups."}
                        </Typography>
                        <Box sx={{ display: 'flex', gap: 2 }}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleRunSim}
                                disabled={!contestSize || !simOption || (lineups.length === 0 && uploadedLineups.length === 0)}
                            >
                                Run Sim
                            </Button>
                            <Box sx={{ position: 'relative' }}>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    startIcon={<CloudUpload />}
                                    onClick={triggerFileUpload}
                                >
                                    Upload Lineups
                                </Button>
                                <input
                                    type="file"
                                    accept=".csv"
                                    style={{ display: 'none' }}
                                    ref={fileInputRef}
                                    onChange={handleFileUpload}
                                />
                                <Tooltip title="Upload a CSV of lineups with columns for each position, exactly like you would upload to your DFS site.">
                                    <Help sx={{ position: 'absolute', right: -24, top: '50%', transform: 'translateY(-50%)', cursor: 'help' }} />
                                </Tooltip>
                            </Box>
                        </Box>
                    </Box>
                </CardContent>
            </Card>

            {isLoading && (
                <Card elevation={3}>
                    <CardContent>
                        <Typography variant="h6" gutterBottom>Simulation in progress...</Typography>
                        <Typography variant="body2" color="text.secondary" gutterBottom>
                            The Beta Testing Program is free, so this might take a min or two on our cheap servers
                        </Typography>
                        <Skeleton variant="rectangular" width="100%" height={100} />
                        <Skeleton variant="rectangular" width="100%" height={400} sx={{ mt: 2 }} />
                    </CardContent>
                </Card>
            )}

            {showResults && !isLoading && (
                <>
                    <Card elevation={3}>
                        <CardContent>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                                <Typography variant="h6">Lineup Results</Typography>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    startIcon={<GetApp />}
                                    onClick={handleDownloadResults}
                                >
                                    Download Results CSV
                                </Button>
                            </Box>
                            <LineupResultsTable data={lineupResults} sport={sport as 'MLB' | 'NFL'} isShowdown={isShowdown} />
                        </CardContent>
                    </Card>

                    <Card elevation={3}>
                        <CardContent>
                            <Typography variant="h6" sx={{ mb: 2 }}>
                                {isShowdown ? "Simulated CPT Metrics" : "Simulated Stack Metrics"}
                            </Typography>
                            <StackFrequencyTable data={stackFrequencySummary} />
                        </CardContent>
                    </Card>
                </>
            )}
        </Box>
    );
}

export default ContestSimulator;