import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { useAuth } from '../context/AuthContext';
import AuthModal from './Auth/AuthModal';
import SlateFilters from './SlateFilters';
import { useSlateContext } from '../context/SlateContext';

function Header() {
    const { isLoggedIn, logout, user } = useAuth();
    const [authModalOpen, setAuthModalOpen] = useState(false);
    const { updateFilters } = useSlateContext();

    const handleFilterChange = (sport: string, date: string | null, slateId: number | null, slateType: 'Classic' | 'Showdown' | null) => {
        console.log('Filter changed in Header', { sport, date, slateId, slateType });
        updateFilters(sport, date, slateId, slateType);
    };

    return (
        <AppBar
            position="fixed"
            sx={{
                zIndex: (theme) => theme.zIndex.drawer + 1,
                bgcolor: '#ffffff',
                color: '#000000',
                boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2)',
            }}
        >
            <Toolbar sx={{ minHeight: 64, px: 2 }}>
                <img
                    src="/logo.png"
                    alt="Daily Fantasy Optimizer Logo"
                    style={{ height: '100px', marginRight: '10px' }}
                />
                <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center', mx: 2 }}>
                    <SlateFilters onFilterChange={handleFilterChange} />
                </Box>
                <Box sx={{ flexShrink: 0 }}>
                    {isLoggedIn ? (
                        <>
                            <Typography variant="body2" sx={{ mr: 1, display: 'inline', fontSize: '0.8rem', color: '#000000' }}>
                                {user?.email}
                            </Typography>
                            <Button color="inherit" onClick={logout} size="small" sx={{ fontSize: '0.8rem', color: '#000000' }}>Logout</Button>
                        </>
                    ) : (
                        <Button color="inherit" onClick={() => setAuthModalOpen(true)} size="small" sx={{ fontSize: '0.8rem', color: '#000000' }}>Login / Sign Up</Button>
                    )}
                </Box>
            </Toolbar>
            <AuthModal open={authModalOpen} onClose={() => setAuthModalOpen(false)} />
        </AppBar>
    );
}

export default Header;