import React, { createContext, useState, useContext, ReactNode, useCallback } from 'react';
import { StackingInfo, NFLTeamStack } from '../types';

// interface NFLTeamStack {
//     team: string;
//     opponent: string;
//     isPrimaryStack: boolean;
//     isSecondaryStack: boolean;
//     qbStackSize: number;
//     bringBackSize: number;
//     allTeammatesSelected: boolean;
//     allOpponentsSelected: boolean;
//     eligibleTeammates: Set<string>;
//     eligibleOpponents: Set<string>;
// }



interface PersistentState {
    numberOfLineups: number;
    minProj: number | '';
    minOwn: number | '';
    maxOwn: number | '';
    minValue: number | '';
    selectedPlayerIds: string[];
    showdownCombinations: { [key: string]: boolean };
    stackingInfo: StackingInfo;
}

interface PersistentStateContextType {
    persistentState: PersistentState;
    updatePersistentState: (updates: Partial<PersistentState>) => void;
}

const PersistentStateContext = createContext<PersistentStateContextType | undefined>(undefined);

export const PersistentStateProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [persistentState, setPersistentState] = useState<PersistentState>({
        numberOfLineups: 10,
        minProj: '',
        minOwn: '',
        maxOwn: '',
        minValue: '',
        selectedPlayerIds: [],
        showdownCombinations: {
            'TEAM1_5-TEAM2_1': true,
            'TEAM1_4-TEAM2_2': true,
            'TEAM1_3-TEAM2_3': true,
            'TEAM1_2-TEAM2_4': true,
            'TEAM1_1-TEAM2_5': true,
            'TEAM2_5-TEAM1_1': true,
            'TEAM2_4-TEAM1_2': true,
            'TEAM2_3-TEAM1_3': true,
            'TEAM2_2-TEAM1_4': true,
            'TEAM2_1-TEAM1_5': true,
        },
        stackingInfo: {
            primaryStacks: [],
            secondaryStacks: [],
            usePrimaryStacks: false,
            useSecondaryStacks: false,
            primaryStackCount: null,
            secondaryStackCount: null,
            useBringBack: false,
            rbDstStack: false,
            bringBackSize: 0,
            nflTeamStacks: [],
        }
    });

    const updatePersistentState = useCallback((updates: Partial<PersistentState>) => {
        console.log('Updating persistent state:', updates);
        setPersistentState(prevState => {
            const newState = { ...prevState };
            let hasChanged = false;

            (Object.keys(updates) as Array<keyof PersistentState>).forEach(key => {
                if (JSON.stringify(prevState[key]) !== JSON.stringify(updates[key])) {
                    (newState[key] as any) = updates[key];
                    hasChanged = true;
                }
            });

            if (hasChanged) {
                console.log('Persistent state updated');
                return newState;
            } else {
                console.log('No changes in persistent state');
                return prevState;
            }
        });
    }, []);

    return (
        <PersistentStateContext.Provider value={{ persistentState, updatePersistentState }}>
            {children}
        </PersistentStateContext.Provider>
    );
};

export const usePersistentState = () => {
    const context = useContext(PersistentStateContext);
    if (context === undefined) {
        throw new Error('usePersistentState must be used within a PersistentStateProvider');
    }
    return context;
};