import React, { useState, Dispatch, useEffect, SetStateAction, useMemo, useRef, useCallback } from 'react';
import { Box, Chip, TextField, Typography, Paper, Tooltip, Button, Grid, Alert, LinearProgress } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { DataGrid, GridColDef, GridRowModel, GridValueGetter, GridCellParams, GridPreProcessEditCellProps, GridCellEditStopParams, GridRowSelectionModel } from '@mui/x-data-grid';
import {
    collection, getDocs, query, where, orderBy, onSnapshot,
    QuerySnapshot,
    DocumentChange, addDoc, serverTimestamp
} from 'firebase/firestore';
import { db } from '../../firebase';
import GameDisplay from './Game';
import { useSlateContext } from '../../context/SlateContext';
import { useAuth } from '../../context/AuthContext';
import { useSettings } from '../../context/SettingsContext';
import { getAuth } from 'firebase/auth';
import LineupResults from '../LineupResults';
import EditIcon from '@mui/icons-material/Edit';
import DownloadIcon from '@mui/icons-material/Download';
import LineupTable from './LineupTable';
import PlayerExposureTable from './PlayerExposureTable';
import { useTheme, useMediaQuery } from '@mui/material';
import { Card, CardContent } from '@mui/material';
import { CircularProgress } from '@mui/material';
import { usePersistentState } from '../../context/PersistentStateContext';
import { NFLTeamStack } from '../../types';

// TESTING

// const positions = sport === 'NFL' 
//     ? ['QB', 'RB', 'WR', 'TE', 'DST'] 
//     : ['P', 'C', '1B', '2B', '3B', 'SS', 'OF'];

interface Player {
    id: string;
    Opp: string;
    opponent?: string; // Add this line
    Order: number;
    Own: number;
    Player: string;
    Pos: string;
    Proj: number;
    Salary: number;
    StdDev: number;
    Team: string;
    fantasyPoints: number;
    fantasyPointsProjorig: number;
    gameId: number;
    gameStartTime: string;
    playerFirstName: string;
    playerId: number;
    playerLastName: string;
    ptsValue: number;
    slate_identifier: number;
    Olimit: number;
    playerPosition?: string;
    position?: string;
    teamAbbreviation?: string;
    originalSalary?: number;
    originalProj?: number;
}

interface LineupPlayer {
    playerId: string;
    position: string;
    projection: number;
    salary: number;
}

interface ProcessedLineup {
    QB: LineupPlayer[];
    RB: LineupPlayer[];
    WR: LineupPlayer[];
    TE: LineupPlayer[];
    FLEX: LineupPlayer[];
    DST: LineupPlayer[];
    [key: string]: LineupPlayer[]; // Index signature
}


interface Game {
    id: string;
    awayTeamAbbreviation: string;
    homeTeamAbbreviation: string;
    startTime: string;
    slate_identifier: number;
}

interface StackingInfo {
    primaryStacks: string[];
    secondaryStacks: string[];
    usePrimaryStacks: boolean;
    useSecondaryStacks: boolean;
    primaryStackCount: number | null;
    secondaryStackCount: number | null;
}

interface EditedPlayer {
    adjusted_proj?: number;
    StdDev?: number;
    Olimit?: number;
}

interface Lineup {
    lineupId: string;
    totalProjection: number;
    totalSalary: number;
    players: LineupPlayer[];
}

// export interface PlayerPoolProps {
//     stackingInfo: StackingInfo;
//     numberOfLineups: number;
//     setNumberOfLineups: Dispatch<SetStateAction<number>>;
// }
interface PlayerPoolProps {
    stackingInfo: {
        primaryStacks: string[];
        secondaryStacks: string[];
        usePrimaryStacks: boolean;
        useSecondaryStacks: boolean;
        primaryStackCount: number | null;
        secondaryStackCount: number | null;
        nflTeamStacks: NFLTeamStack[];
    };
    numberOfLineups: number;
    isShowdown: boolean;
    updatePersistentState: (updates: Partial<any>) => void;
}



const PlayerPool = React.memo(function PlayerPool({ numberOfLineups, stackingInfo, updatePersistentState }: PlayerPoolProps) {
    console.log('PlayerPool rendering', { numberOfLineups, stackingInfo }); console.log('PlayerPool rendering', { numberOfLineups, stackingInfo });
    const { isLoggedIn, user } = useAuth();
    const { sport, date, slateId, slateType: rawSlateType } = useSlateContext();
    const slateType = rawSlateType ? (rawSlateType.startsWith('Showdown') ? 'Showdown' : 'Classic') : null;
    const { settings } = useSettings();
    // const [numberOfLineups, setNumberOfLineups] = useState<number>(10);
    const [lineupError, setLineupError] = useState<string | null>(null);
    const [players, setPlayers] = useState<Player[]>([]);
    const [games, setGames] = useState<Game[]>([]);
    const [minProj, setMinProj] = useState<number | ''>('');
    const [minOwn, setMinOwn] = useState<number | ''>('');
    const [maxOwn, setMaxOwn] = useState<number | ''>('');
    const [minValue, setMinValue] = useState<number | ''>('');

    const [selectedTeams, setSelectedTeams] = useState<string[]>([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [showResults, setShowResults] = useState(false);
    const [editedPlayers, setEditedPlayers] = useState<{ [key: string]: EditedPlayer }>({});
    const { persistentState } = usePersistentState();
    const [selectedRows, setSelectedRows] = useState<GridRowSelectionModel>([]);
    const [initialSelectionSet, setInitialSelectionSet] = useState(false);
    const [lineups, setLineups] = useState<any[]>([]);
    const [lineupListener, setLineupListener] = useState<(() => void) | null>(null);
    const [currentJobId, setCurrentJobId] = useState<string | null>(null);
    const [lineupProgress, setLineupProgress] = useState(0);
    const [totalLineups, setTotalLineups] = useState(0);
    const [playerExposures, setPlayerExposures] = useState<{ [key: string]: { name: string, position: string, count: number } }>({});
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [isOptimizerStarting, setIsOptimizerStarting] = useState(false);
    const [warnings, setWarnings] = useState<string[]>([]);
    const [nflTeamStacks, setNFLTeamStacks] = useState<NFLTeamStack[]>(stackingInfo.nflTeamStacks);
    const isShowdown = slateType === 'Showdown';
    const [positions, setPositions] = useState<string[]>([]);


    // const positions = useMemo(() => {
    //     return sport === 'NFL'
    //         ? ['QB', 'RB', 'WR', 'TE', 'DST']
    //         : ['P', 'C', '1B', '2B', '3B', 'SS', 'OF'];
    // }, [sport]);

    const [selectedPositions, setSelectedPositions] = useState<string[]>(positions);




    const editableCellStyle = {
        backgroundColor: '#f0f0f0',
        border: '1px solid #000',
        '&:hover': {
            backgroundColor: '#e0e0e0',
        },
    };

    useEffect(() => {
        console.log('PlayerPool effect running');
        // Log all state variables here
        console.log('State variables:', {
            players,
            games,
            minProj,
            minOwn,
            maxOwn,
            minValue,
            selectedTeams,
            searchTerm,
            selectedRows,
            lineups,
            currentJobId,
            lineupProgress,
            totalLineups,
            playerExposures,
            isOptimizerStarting,
            warnings,
            nflTeamStacks,
        });
    });

    useEffect(() => {
        console.log('Sport or slate changed, resetting state');
        setLineups([]);
        setPlayers([]);
        setSelectedRows([]);
        // Reset other relevant state variables
        setEditedPlayers({});
        setCurrentJobId(null);
        setLineupProgress(0);
        setTotalLineups(0);
        setPlayerExposures({});
        setIsOptimizerStarting(false);
        setWarnings([]);
        // ... reset any other state variables that are sport-specific

        // Optionally, you can re-fetch data for the new sport/slate here
        if (slateId) {
            fetchGamesAndPlayers();
        }
    }, [sport, slateId]);

    useEffect(() => {
        console.log('Updating persistentState with selectedPlayerIds', { count: selectedRows.length });
        updatePersistentState({ selectedPlayerIds: selectedRows });
    }, [selectedRows, updatePersistentState]);

    const fetchGamesAndPlayers = useCallback(async () => {
        console.log('fetchGamesAndPlayers called with:', { slateId, sport, slateType });
        try {
            if (!slateId) return;

            console.log('Fetching games and players for:', { sport, slateId, slateType });

            // Fetch games
            const gamesQuery = query(collection(db, 'slates_games'), where('slate_identifier', '==', slateId));
            const gameSnapshot = await getDocs(gamesQuery);
            const gameList = gameSnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            } as Game));

            setGames(gameList);
            console.log('Fetched games:', gameList);

            // Fetch players
            let playersQuery = query(collection(db, 'data_players'), where('slate_identifier', '==', slateId));

            if (sport === 'NFL') {
                playersQuery = query(playersQuery,
                    where('position', 'in', [
                        'Offense-QB-1',
                        'Offense-RB-1', 'Offense-RB-2', 'Offense-RB-3',
                        'Offense-WR-1', 'Offense-WR-2', 'Offense-WR-3', 'Offense-WR-4',
                        'Offense-TE-1', 'Offense-TE-2',
                        'Defense-DST-1', 'SpecialTeams-K-1', 'Offense-FB-1', 'Offense-FB-2'
                    ])
                );
            }

            const playerSnapshot = await getDocs(playersQuery);
            const playerList = playerSnapshot.docs.map(doc => {
                const data = doc.data();
                return {
                    id: doc.id,
                    ...data,
                    Olimit: data.Olimit || 100,
                    Pos: slateType === 'Showdown' ? (data.Player.includes('CPT') ? 'CPT' : 'FLEX') : (data.playerPosition || data.Pos),
                    Team: data.teamAbbreviation || data.Team,
                    Proj: data.Proj,
                    Salary: data.salary || data.Salary,
                    Opp: data.opponent?.replace(/^(vs |@ )/, '') || data.Opp,
                    Player: data.Player,
                } as Player;
            });

            setPlayers(playerList);
            console.log('Fetched players:', playerList.length);

            // Set teams based on the games
            const allTeams = new Set(gameList.flatMap(game => [game.homeTeamAbbreviation, game.awayTeamAbbreviation]));
            console.log('Setting teams:', Array.from(allTeams));
            setSelectedTeams(Array.from(allTeams));

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }, [slateId, sport, slateType]);

    useEffect(() => {
        console.log('Effect: Fetching games and players', { isLoggedIn, user, slateId, sport, slateType });
        if (isLoggedIn && user && slateId) {
            fetchGamesAndPlayers();
        } else {
            console.log('Not fetching data because:', { isLoggedIn, hasUser: !!user, slateId });
        }
    }, [isLoggedIn, user, slateId, fetchGamesAndPlayers, sport, slateType]);

    useEffect(() => {
        return () => {
            if (lineupListener) {
                lineupListener();
            }
        };
    }, [lineupListener]);

    useEffect(() => {
        updatePersistentState({ selectedPlayerIds: selectedRows });
    }, [selectedRows, updatePersistentState]);

    useEffect(() => {
        if (persistentState.selectedPlayerIds && persistentState.selectedPlayerIds.length > 0) {
            setSelectedRows(persistentState.selectedPlayerIds);
        }
    }, [persistentState.selectedPlayerIds]);

    useEffect(() => {
        const newPositions = isShowdown
            ? ['CPT', 'FLEX']
            : sport === 'NFL'
                ? ['QB', 'RB', 'WR', 'TE', 'DST']
                : ['P', 'C', '1B', '2B', '3B', 'SS', 'OF'];

        setPositions(newPositions);
        setSelectedPositions(newPositions);
    }, [isShowdown, sport]);

    const [regularPlayers, cptPlayers] = React.useMemo(() => {
        console.log('Effect: Updating filtered players', {
            playersLength: players.length,
            selectedPositionsLength: selectedPositions.length,
            selectedTeamsLength: selectedTeams.length,
            searchTerm,
            minProj,
            minValue,
            sport,
            isShowdown
        });
        let filtered = players.filter(player =>
            (sport === 'NFL' ? selectedPositions.includes(player.playerPosition || '') : selectedPositions.includes(player.Pos)) &&
            selectedTeams.includes(sport === 'NFL' ? player.teamAbbreviation || '' : player.Team || '') &&
            (player.Player.toLowerCase().includes(searchTerm.toLowerCase()) ||
                (sport === 'NFL' ? player.teamAbbreviation : player.Team)?.toLowerCase().includes(searchTerm.toLowerCase())) &&
            (minProj === '' || player.Proj >= minProj) &&
            (minValue === '' || (player.Proj / player.Salary) * 1000 >= minValue)
        );

        if (isShowdown) {
            const captainPlayers = filtered.map(player => ({
                ...player,
                id: `${player.id}-CPT`,
                Player: `${player.Player} - CPT`,
                Pos: 'CPT',
                originalSalary: player.Salary,
                originalProj: player.Proj,
                Salary: player.Salary * 1.5,
                Proj: player.Proj * 1.5,
            }));
            return [filtered, captainPlayers];
        }
        return [filtered, []];
    }, [players, selectedPositions, selectedTeams, searchTerm, minProj, minValue, sport, isShowdown]);

    const allPlayers = useMemo(() => {
        console.log('Recalculating allPlayers', {
            sport,
            isShowdown,
            selectedPositions,
            selectedTeams,
            searchTerm,
            minProj,
            minValue,
            totalPlayers: players.length
        });

        const filteredPlayers = players.filter(player => {
            const positionMatch = isShowdown || selectedPositions.includes(player.Pos);
            const teamMatch = selectedTeams.includes(sport === 'NFL' ? player.teamAbbreviation || '' : player.Team || '');
            const searchMatch = player.Player.toLowerCase().includes(searchTerm.toLowerCase()) ||
                (sport === 'NFL' ? player.teamAbbreviation : player.Team)?.toLowerCase().includes(searchTerm.toLowerCase());
            const projMatch = minProj === '' || player.Proj >= minProj;
            const valueMatch = minValue === '' || (player.Proj / player.Salary) * 1000 >= minValue;

            if (!positionMatch || !teamMatch || !searchMatch || !projMatch || !valueMatch) {
                console.log('Filtered out player:', {
                    player: player.Player,
                    position: player.Pos,
                    team: sport === 'NFL' ? player.teamAbbreviation : player.Team,
                    positionMatch,
                    teamMatch,
                    searchMatch,
                    projMatch,
                    valueMatch
                });
            }

            return positionMatch && teamMatch && searchMatch && projMatch && valueMatch;
        });

        console.log('Filtered players:', filteredPlayers.length);
        return filteredPlayers;
    }, [players, selectedPositions, selectedTeams, searchTerm, minProj, minValue, sport, isShowdown]);

    const initialSelectionRef = useRef(false);
    const [selectedRegularRows, setSelectedRegularRows] = useState<string[]>([]);
    const [selectedCPTRows, setSelectedCPTRows] = useState<string[]>([]);

    const handleRowSelectionModelChange = useCallback((newSelectionModel: GridRowSelectionModel) => {
        console.log('Selection changed:', newSelectionModel);
        setSelectedRows(newSelectionModel as string[]);

        // Separate regular and CPT selections
        const regularSelections = newSelectionModel.filter(id => !id.toString().endsWith('-CPT'));
        const cptSelections = newSelectionModel.filter(id => id.toString().endsWith('-CPT'));

        setSelectedRegularRows(regularSelections as string[]);
        setSelectedCPTRows(cptSelections as string[]);

        updatePersistentState({
            selectedPlayerIds: newSelectionModel as string[]
        });
    }, [updatePersistentState]);

    useEffect(() => {
        if (regularPlayers.length > 0 && !initialSelectionRef.current) {
            const regularIds = regularPlayers.map(player => player.id);
            let cptIds: string[] = [];
            setSelectedRegularRows(regularIds);
            if (isShowdown) {
                const cptIds = cptPlayers.map(player => player.id);
                setSelectedCPTRows(cptIds);
            }
            updatePersistentState({
                selectedPlayerIds: [...regularIds, ...(isShowdown ? cptIds : [])]
            });
            initialSelectionRef.current = true;
        }
    }, [regularPlayers, cptPlayers, isShowdown]);


    useEffect(() => {
        if (sport === 'NFL') {
            setMinProj(3);
            updatePersistentState({ minProj: 3 });
        }
    }, [sport]);



    useEffect(() => {
        if (allPlayers.length > 0 && !initialSelectionRef.current) {
            // console.log('Setting initial selection');
            const idsToSelect = allPlayers.map(player => player.id);
            setSelectedRows(idsToSelect);
            initialSelectionRef.current = true;
        }
    }, [allPlayers]);

    useEffect(() => {
        if (settings.positionLimits && players.length > 0) {
            const updatedPlayers = players.map(player => {
                const newOlimit = settings.positionLimits[player.Pos as keyof typeof settings.positionLimits] || settings.positionLimits.ALL;
                // Only update if the Olimit has actually changed
                if (player.Olimit !== newOlimit) {
                    return { ...player, Olimit: newOlimit };
                }
                return player;
            });
            // Only setPlayers if there were actually changes
            if (updatedPlayers.some((newPlayer, index) => newPlayer !== players[index])) {
                setPlayers(updatedPlayers);
            }
        }
    }, [settings.positionLimits, players]);

    useEffect(() => {
        if (numberOfLineups > 0) {
            setLineupProgress((lineups.length / totalLineups) * 100);
        }
    }, [lineups.length, numberOfLineups]);

    useEffect(() => {
        console.log('Effect: Updating lineups', { lineups: lineups.length });
        if (lineups.length > 0) {
            const newExposures: { [key: string]: { name: string, position: string, count: number } } = {};
            lineups.forEach(lineup => {
                lineup.players.forEach((player: LineupPlayer) => {
                    if (!newExposures[player.playerId]) {
                        newExposures[player.playerId] = { name: player.playerId, position: player.position, count: 0 };
                    }
                    newExposures[player.playerId].count += 1;
                });
            });
            setPlayerExposures(newExposures);
        }
    }, [lineups]);

    useEffect(() => {
        if (isOptimizerStarting) {
            const timeout = setTimeout(() => {
                if (lineups.length === 0) {
                    setIsOptimizerStarting(false);
                    alert("The optimizer timed out without producing any lineups. Please check your settings and try again.");
                }
            }, 60000);  // 1 minute timeout

            return () => clearTimeout(timeout);
        }
    }, [isOptimizerStarting, lineups.length]);

    useEffect(() => {
        // Update persistent state whenever nflTeamStacks changes
        updatePersistentState({
            stackingInfo: {
                ...stackingInfo,
                nflTeamStacks: nflTeamStacks,
            },
        });
    }, [nflTeamStacks]);





    const uncheckedPlayers = allPlayers.filter(player => !selectedRows.includes(player.id));
    const positionOrder = ['P', 'P', 'C', '1B', '2B', '3B', 'SS', 'OF', 'OF', 'OF'];

    const playerExposureList = useMemo(() => {
        return Object.entries(playerExposures).map(([playerId, data]) => ({
            id: playerId,
            playerId,
            name: data.name,
            position: data.position,
            exposure: data.count / lineups.length
        }));
    }, [playerExposures, lineups.length]);


    const convertToCSV = (rows: Player[], columns: GridColDef[]): string => {
        // Get the field names from the columns array
        const columnFields = columns.map((col) => col.field);

        // Create the CSV header based on the column headers
        const headers = columns.map((col) => col.headerName).join(',') + '\n';

        // Filter the rows to include only the data for the fields in the DataGrid columns
        const filteredRows = rows.map((row) => {
            return columnFields.map((field) => {
                if (field === 'ptsValue') {
                    const proj = row.Proj;
                    const salary = row.Salary;
                    if (proj != null && salary != null) {
                        return (proj / (salary / 1000)).toFixed(2);
                    }
                    return '';
                }
                if (field === 'Proj' && row.Pos === 'CPT') {
                    return (row.Proj / 1.5).toFixed(2);
                }
                if (field === 'Salary' && row.Pos === 'CPT') {
                    return (row.Salary / 1.5).toFixed(0);
                }
                const value = row[field as keyof Player];
                return typeof value === 'string' ? `"${value}"` : value;
            }).join(',');
        }).join('\n');

        // Return the headers and filtered rows as the CSV content
        return headers + filteredRows;
    };

    const convertToCSVlu = (lineups: Lineup[]): string => {
        const positionOrder = isShowdown
            ? ['CPT', 'FLEX', 'FLEX', 'FLEX', 'FLEX', 'FLEX']
            : sport === 'NFL'
                ? ['QB', 'RB', 'RB', 'WR', 'WR', 'WR', 'TE', 'FLEX', 'DST']
                : ['P', 'P', 'C', '1B', '2B', '3B', 'SS', 'OF', 'OF', 'OF'];

        const headers = ['Lineup ID', 'Total Projection', 'Total Salary', ...positionOrder];
        const csvRows = [headers.join(',')];

        const processShowdownLineup = (players: LineupPlayer[]): (LineupPlayer | null)[] => {
            const processed: (LineupPlayer | null)[] = new Array(6).fill(null);
            const captain = players.find(p => p.position === 'CPT');
            if (captain) processed[0] = captain;
            const flexPlayers = players.filter(p => p.position === 'FLEX');
            flexPlayers.forEach((player, index) => {
                if (index < 5) processed[index + 1] = player;
            });
            return processed;
        };

        const processNFLLineup = (players: LineupPlayer[]): (LineupPlayer | null)[] => {
            const processed: { [key: string]: LineupPlayer[] } = {
                QB: [], RB: [], WR: [], TE: [], DST: [], FLEX: []
            };

            players.forEach(player => {
                if (['RB', 'WR', 'TE'].includes(player.position)) {
                    if (processed[player.position].length < (player.position === 'TE' ? 1 : (player.position === 'RB' ? 2 : 3))) {
                        processed[player.position].push(player);
                    } else {
                        processed.FLEX.push(player);
                    }
                } else {
                    processed[player.position].push(player);
                }
            });

            return [
                processed.QB[0] || null,
                processed.RB[0] || null,
                processed.RB[1] || null,
                processed.WR[0] || null,
                processed.WR[1] || null,
                processed.WR[2] || null,
                processed.TE[0] || null,
                processed.FLEX[0] || null,
                processed.DST[0] || null
            ];
        };

        const processMLBLineup = (players: LineupPlayer[]): (LineupPlayer | null)[] => {
            const processedPlayers: (LineupPlayer | null)[] = new Array(10).fill(null);
            const positionCounts: { [key: string]: number } = {};
            const positionOrder = ['P', 'P', 'C', '1B', '2B', '3B', 'SS', 'OF', 'OF', 'OF'];

            players.forEach(player => {
                const positionIndex = positionOrder.indexOf(player.position);
                if (positionIndex !== -1) {
                    const count = positionCounts[player.position] || 0;
                    let availableIndex = -1;

                    if (player.position === 'OF') {
                        availableIndex = positionOrder.findIndex((pos, index) => pos === 'OF' && !processedPlayers[index]);
                    } else {
                        availableIndex = positionOrder.indexOf(player.position, positionIndex + count);
                    }

                    if (availableIndex !== -1 && !processedPlayers[availableIndex]) {
                        processedPlayers[availableIndex] = player;
                        positionCounts[player.position] = (positionCounts[player.position] || 0) + 1;
                    }
                }
            });

            return processedPlayers;
        };


        lineups.forEach(lineup => {
            let processedPlayers: (LineupPlayer | null)[];
            if (isShowdown) {
                processedPlayers = processShowdownLineup(lineup.players);
            } else if (sport === 'NFL') {
                processedPlayers = processNFLLineup(lineup.players);
            } else {
                processedPlayers = processMLBLineup(lineup.players);
            }

            const row = [
                lineup.lineupId,
                lineup.totalProjection.toFixed(2),
                lineup.totalSalary,
                ...positionOrder.map((pos, index) => {
                    const player = processedPlayers[index];
                    return player ? `${player.playerId}` : '';
                })
            ];
            csvRows.push(row.join(','));
        });

        return csvRows.join('\n');
    };

    const downloadCSVlu = () => {
        const csv = convertToCSVlu(lineups);
        const BOM = '\uFEFF';
        const csvContent = BOM + csv;
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        if (link.download !== undefined) {
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', `${sport}_lineups.csv`);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };

    useEffect(() => {
        if (persistentState.selectedPlayerIds && persistentState.selectedPlayerIds.length > 0) {
            setSelectedRows(persistentState.selectedPlayerIds);
        } else if (allPlayers.length > 0) {
            const allIds = allPlayers.map(player => player.id);
            setSelectedRows(allIds);
            updatePersistentState({
                selectedPlayerIds: allIds
            });
        }
    }, [allPlayers, persistentState.selectedPlayerIds, updatePersistentState]);


    const downloadCSV = (csv: string, filename: string): void => {
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        if (link.download !== undefined) { // Feature detection
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', filename);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };

    const handleDownloadCSV = () => {
        const csvData = convertToCSV(allPlayers, columns); // Assuming `filteredPlayers` and `columns` are the DataGrid rows and columns
        downloadCSV(csvData, 'player_pool.csv');
    };



    const handlePositionToggle = (position: string) => {
        if (position === 'ALL') {
            setSelectedPositions(selectedPositions.length === positions.length ? [] : positions);
        } else {
            setSelectedPositions(prev =>
                prev.includes(position) ? prev.filter(p => p !== position) : [...prev, position]
            );
        }
    };

    const handleTeamToggle = (team: string) => {
        if (team === 'ALL') {
            setSelectedTeams(selectedTeams.length === games.length * 2 ? [] : games.flatMap(game => [game.homeTeamAbbreviation, game.awayTeamAbbreviation]));
        } else {
            setSelectedTeams(prev =>
                prev.includes(team) ? prev.filter(t => t !== team) : [...prev, team]
            );
        }
    };

    const handleCellEdit = (params: GridCellEditStopParams) => {
        const { id, field, value } = params;
        setEditedPlayers(prev => ({
            ...prev,
            [id]: {
                ...prev[id],
                [field]: Number(value)
            }
        }));

        setPlayers(prevPlayers =>
            prevPlayers.map(player =>
                player.id === id ? { ...player, [field]: Number(value) } : player
            )
        );
    };


    const columns: GridColDef[] = useMemo(() => [

        {
            field: 'Player',
            headerName: 'Name',
            width: 160,
        },
        {
            field: 'Pos',
            headerName: 'Pos',
            width: 70,
            renderCell: (params) => {
                return isShowdown ? params.row.playerPosition : (sport === 'NFL' ? params.row.playerPosition : params.row.Pos);
            }
        },
        {
            field: 'Salary',
            headerName: 'Salary',
            width: 100,
            renderCell: (params: GridCellParams<Player>) => {
                return Math.round(params.row.Salary);
            },
        },
        {
            field: 'Team',
            headerName: 'Team',
            width: 80,
            renderCell: (params) => {
                return sport === 'NFL' ? params.row.teamAbbreviation : params.row.Team;
            }
        },
        {
            field: 'Opp',
            headerName: 'Opp',
            width: 80,
            renderCell: (params) => {
                if (sport === 'NFL') {
                    return params.row.opponent || '';
                } else {
                    return params.row.Opp || '';
                }
            }
        },
        {
            field: 'Order',
            headerName: sport === 'NFL' ? 'Depth' : 'Order',
            width: 70,
            renderCell: (params) => {
                if (sport === 'NFL') {
                    const position = params.row.position || '';
                    // Extract the last two parts of the position string (e.g., "QB-3" from "Offense-QB-3")
                    const parts = position.split('-');
                    return parts.length >= 2 ? `${parts[parts.length - 2]}-${parts[parts.length - 1]}` : position;
                } else {
                    return params.value === 0 ? 'P' : params.value;
                }
            }
        },
        {
            field: 'Proj',
            headerName: 'Proj',
            width: 100,
            editable: true,
            renderCell: (params: GridCellParams<Player>) => {
                const projValue = editedPlayers[params.id]?.adjusted_proj ?? params.row.Proj;

                return (
                    <Box sx={{
                        backgroundColor: '#f0f0f0',
                        padding: '4px',
                        borderRadius: '4px',
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}>
                        {projValue !== 0 ? Number(projValue).toFixed(2) : ''}
                    </Box>
                );
            },
            sortComparator: (v1, v2, param1, param2) => {
                const getProjection = (value: any, row: any) => {
                    if (!row) {
                        return value ?? 0;
                    }
                    return editedPlayers[row.id]?.adjusted_proj ?? row.Proj ?? 0;
                };
                const proj1 = getProjection(v1, param1.api.getRow(param1.id));
                const proj2 = getProjection(v2, param2.api.getRow(param2.id));
                return proj1 - proj2;
            },
            preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
                const { props } = params;
                return { ...props, value: Number(props.value) || 0 };
            },
        },
        {
            field: 'StdDev',
            headerName: 'StdDev',
            width: 100,
            editable: true,
            renderHeader: () => (
                <Tooltip title="This is the standard deviation of the player's projection based on the models range of outcomes, NOT based on historical actuals">
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        StdDev
                        <EditIcon fontSize="small" sx={{ color: '#888', marginLeft: '4px' }} />
                    </Box>
                </Tooltip>

            ),
            renderCell: (params: GridCellParams) => {
                const value = editedPlayers[params.id]?.StdDev ?? params.value;
                return (
                    <Box
                        sx={{
                            backgroundColor: '#f0f0f0',
                            padding: '4px',
                            borderRadius: '4px',
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                    >
                        {value != null ? Number(value).toFixed(2) : ''}
                    </Box>
                );
            },
            preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
                const { props } = params;
                return { ...props, value: Number(props.value) };
            },
        },
        {
            field: 'ptsValue',
            headerName: 'Value',
            width: 100,
            renderCell: (params: GridCellParams) => {
                const proj = editedPlayers[params.id]?.adjusted_proj ?? params.row.Proj;
                const salary = params.row.Salary;
                if (!proj || !salary) return '';
                const value = (proj / (salary / 1000)).toFixed(2);
                return value;
            },
        },
        // {
        //     field: 'Own',
        //     headerName: 'Own%',
        //     width: 100,
        //     editable: true,
        //     type: 'number',
        //     renderCell: (params: GridCellParams) => {
        //         return params.value != null ? `${Number(params.value).toFixed(2)}%` : '';
        //     },
        // },
        {
            field: 'Own',
            headerName: 'pOwn%',
            width: 100,
            editable: true,
            renderHeader: () => (
                <Tooltip title="This is the ML and Simulation based Projected Ownership for each player">
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        Own
                        <EditIcon fontSize="small" sx={{ color: '#888', marginLeft: '4px' }} />
                    </Box>
                </Tooltip>

            ),
            renderCell: (params: GridCellParams) => {
                const value = editedPlayers[params.id]?.StdDev ?? params.value;
                return (
                    <Box
                        sx={{
                            backgroundColor: '#f0f0f0',
                            padding: '4px',
                            borderRadius: '4px',
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                    >
                        {value != null ? `${Number(params.value).toFixed(2)}%` : ''}
                    </Box>
                );
            },
            preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
                const { props } = params;
                return { ...props, value: Number(props.value) };
            },
        },
        {
            field: 'Olimit',
            headerName: 'Olimit',
            width: 100,
            editable: true,
            renderHeader: () => (
                <Tooltip title="This caps each player at their specific Own% Limit. We apply some buffer to prevent the optimizer from stalling out where needed">
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        Own Limit
                        <EditIcon fontSize="small" sx={{ color: '#888', marginLeft: '4px' }} />
                    </Box>
                </Tooltip>
            ),
            renderCell: (params: GridCellParams) => {
                const value = editedPlayers[params.id]?.Olimit ?? params.value;
                return (
                    <Box
                        sx={{
                            backgroundColor: '#f0f0f0',
                            padding: '4px',
                            borderRadius: '4px',
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                    >
                        {value != null ? `${Number(value).toFixed(0)}%` : ''}
                    </Box>
                );
            },
            preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
                const { props } = params;
                const value = Number(props.value);
                return { ...props, value: isNaN(value) ? 100 : Math.min(Math.max(value, 0), 100) };
            },
        },
        {
            field: 'gameStartTime',
            headerName: 'Game Time',
            width: 160,
            renderCell: (params: GridCellParams) => {
                const value = params.value as string | undefined;

                if (!value) {
                    return ''; // Return an empty string if value is undefined or null
                }

                const utcDate = new Date(value);
                const options: Intl.DateTimeFormatOptions = {
                    hour: '2-digit',
                    minute: '2-digit',
                    timeZone: 'America/New_York', // Eastern Time Zone
                };
                const easternTime = utcDate.toLocaleTimeString('en-US', options);
                return easternTime;
            }
        },
    ], [editedPlayers, sport, isShowdown]);

    // const handleLineupNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    //     const value = parseInt(e.target.value);
    //     if (isNaN(value) || value < 1) {
    //         setLineupError("Minimum number of lineups is 1");
    //         setNumberOfLineups(1);
    //     } else if (value > 500) {
    //         setLineupError("Maximum number of lineups is 500");
    //         setNumberOfLineups(500);
    //     } else {
    //         setLineupError(null);
    //         setNumberOfLineups(value);
    //     }
    // };
    const handleLineupNumberChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(e.target.value);
        if (isNaN(value) || value < 1) {
            setLineupError("Minimum number of lineups is 1");
            updatePersistentState({ numberOfLineups: 1 });
        } else if (value > 1001) {
            setLineupError("Maximum number of lineups is 1000");
            updatePersistentState({ numberOfLineups: 1000 });
        } else {
            setLineupError(null);
            updatePersistentState({ numberOfLineups: value });
        }
    }, [updatePersistentState]);

    const handleMinProjChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value === '' ? '' : Number(e.target.value);
        setMinProj(value);
        updatePersistentState({ minProj: value });
    };

    const handleMinOwnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value === '' ? '' : Number(e.target.value);
        setMinOwn(value);
        updatePersistentState({ minOwn: value });
    };

    const handleMaxOwnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value === '' ? '' : Number(e.target.value);
        setMaxOwn(value);
        updatePersistentState({ maxOwn: value });
    };

    const handleMinValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value === '' ? '' : Number(e.target.value);
        setMinValue(value);
        updatePersistentState({ minValue: value });
    };



    const listenForLineupUpdates = (jobId: string) => {
        const auth = getAuth();
        const user = auth.currentUser;

        if (!user) {
            console.error('User not authenticated');
            return;
        }

        console.log(`Listening for updates. User ID: ${user.uid}, Job ID: ${jobId}`);

        const lineupsRef = collection(db, 'lineups');
        const q = query(
            lineupsRef,
            where('userId', '==', user.uid),
            where('jobId', '==', jobId),
            orderBy('lineupId')
        );

        const unsubscribe = onSnapshot(q, (snapshot: QuerySnapshot) => {
            snapshot.docChanges().forEach((change: DocumentChange) => {
                if (change.type === 'added') {
                    const newLineup = change.doc.data();
                    if (newLineup.error) {
                        console.error('Error in lineup generation:', newLineup.error);
                        alert('Unable to build lineups. Please review your settings, particularly the ownership limits, and try again.');
                        setIsOptimizerStarting(false);
                        unsubscribe();
                    } else {
                        setLineups((prevLineups) => {
                            const updatedLineup = {
                                ...newLineup,
                                players: newLineup.players.map((player: any) => ({
                                    playerId: player.playerId,
                                    position: player.position,
                                    projection: player.projection,
                                    salary: player.salary
                                }))
                            };
                            const updatedLineups = [...prevLineups, updatedLineup];
                            console.log(`Updated lineups. New count: ${updatedLineups.length}`);
                            return updatedLineups;
                        });
                        if (lineups.length === 1) {
                            setIsOptimizerStarting(false);
                        }
                    }
                }
            });
        }, (error) => {
            console.error("Error listening for lineup updates:", error);
            alert('Unable to build lineups. Please review your settings, particularly the ownership limits, and try again.');
            setIsOptimizerStarting(false);
        });

        setLineupListener(() => unsubscribe);
    };


    const handleBuildLineups = async () => {
        console.log('Building lineups');
        if (numberOfLineups > 1000) {
            setLineupError("Maximum number of lineups is 1000");
            return;
        }

        setLineupProgress(0);
        setLineups([]);
        setWarnings([]);
        setShowResults(false);

        setCurrentJobId(null);
        setTotalLineups(numberOfLineups);
        setIsOptimizerStarting(true);


        try {
            const auth = getAuth();
            const user = auth.currentUser;

            if (!user) {
                throw new Error('User not authenticated');
            }

            const idToken = await user.getIdToken();

            const playersWithEdits = players.map(player => ({
                ...player,
                ...(editedPlayers[player.id] || {}),
                isCaptain: player.id.endsWith('-CPT')
            }));

            const excludedPlayers = players.filter(player => !selectedRows.includes(player.id));

            const formattedShowdownCombinations = Object.entries(persistentState.showdownCombinations)
                .filter(([_, isEnabled]) => isEnabled)
                .reduce((acc, [combination]) => {
                    acc[combination] = true;
                    return acc;
                }, {} as { [key: string]: boolean });

            const requestBody = {
                settings: {
                    ...settings,
                    numberOfLineups,
                    usePrimaryStacks: stackingInfo.usePrimaryStacks,
                    useSecondaryStacks: stackingInfo.useSecondaryStacks,
                    primaryStackCount: stackingInfo.primaryStackCount,
                    secondaryStackCount: stackingInfo.secondaryStackCount,
                    primaryStacks: stackingInfo.primaryStacks,
                    secondaryStacks: stackingInfo.secondaryStacks,
                    showdownCombinations: settings.showdownCombinations,
                },
                sport,
                date,
                slateId,
                minProj: minProj === '' ? null : minProj,
                minOwn: minOwn === '' ? null : minOwn,
                maxOwn: maxOwn === '' ? null : maxOwn,
                minValue: minValue === '' ? null : minValue,
                players: playersWithEdits,
                userId: user.uid,
                excludedPlayers: excludedPlayers,
                nflStacks: sport === 'NFL' ? nflTeamStacks
                    .filter(stack => stack.isPrimaryStack)
                    .map(stack => ({
                        team: stack.team,
                        opponent: stack.opponent,
                        qbStackSize: stack.qbStackSize,
                        bringBackSize: stack.bringBackSize,
                        eligibleTeammates: Array.from(stack.eligibleTeammates),
                        eligibleOpponents: Array.from(stack.eligibleOpponents),
                    })) : [],
            };

            console.log('API Request Body:', JSON.stringify(requestBody, null, 2));

            // Choose the correct API URL based on the sport and slate type
            const apiUrls = {
                'NFL': {
                    'Classic': 'https://ue-nfl-optov2-450591700064.us-central1.run.app/submit_job',
                    'Showdown': 'https://ue-shw-optov-450591700064.us-central1.run.app/submit_job'
                },
                'MLB': {
                    'Classic': 'https://ue-mlb-optov2-450591700064.us-central1.run.app/submit_job',
                    'Showdown': 'https://ue-shw-optov-450591700064.us-central1.run.app/submit_job'
                }
            } as const;

            const apiUrl = apiUrls[sport as keyof typeof apiUrls][slateType as 'Classic' | 'Showdown'];

            const response = await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${idToken}`
                },
                body: JSON.stringify(requestBody),
            });



            const data = await response.json();
            const jobId = data.job_id;
            console.log(`Received job_id: ${jobId}`);
            setCurrentJobId(jobId);

            await addDoc(collection(db, 'user_jobs'), {
                userId: user.uid,
                slateId: slateId,
                jobId: jobId,
                createdAt: serverTimestamp()
            });

            // Start listening for lineup updates
            listenForLineupUpdates(jobId);
            console.log(`Started listening for updates with job ID: ${jobId}`);

        } catch (error) {
            console.error('Error:', error);
            setIsOptimizerStarting(false);
            alert('Unable to build lineups. Please review your settings, particularly the ownership limits, and try again.');
        }
    };

    const handleProcessRowUpdateError = (error: Error) => {
        console.error('Error processing row update:', error);
    };

    const memoizedGameDisplays = useMemo(() => {
        return games.map(game => (
            <GameDisplay
                key={game.id}
                game={game}
                selectedTeams={selectedTeams}
                onTeamToggle={handleTeamToggle}
            />
        ));
    }, [games, selectedTeams, handleTeamToggle]);

    const processNFLPlayersIntoTeamStacks = (players: Player[]): NFLTeamStack[] => {
        const teamMap = new Map<string, Player[]>();
        const opponentMap = new Map<string, string>();

        players.forEach(player => {
            const team = player.teamAbbreviation;
            const opponent = player.opponent ? player.opponent.replace(/^(vs |@ )/, '') : '';
            if (!team || !opponent) return;

            if (!teamMap.has(team)) {
                teamMap.set(team, []);
                opponentMap.set(team, opponent);
            }
            teamMap.get(team)?.push(player);
        });

        return Array.from(teamMap).map(([team, teamPlayers]): NFLTeamStack => {
            const opponent = opponentMap.get(team) || '';
            return {
                team,
                opponent,
                players: teamPlayers,
                isPrimaryStack: stackingInfo.primaryStacks.includes(team),
                isSecondaryStack: stackingInfo.secondaryStacks.includes(team),
                allPlayersIncluded: true,
                eligibleTeammates: new Set(teamPlayers.map(p => p.Player)),
                eligibleOpponents: new Set(teamMap.get(opponent)?.map(p => p.Player) || []),
                allTeammatesSelected: false,
                allOpponentsSelected: false,
                qbStackSize: 2,
                bringBackSize: 0,
            };
        });
    };


    const processRowUpdate = (newRow: GridRowModel) => {
        const updatedRow = { ...newRow } as Player;
        setEditedPlayers(prev => ({
            ...prev,
            [updatedRow.id]: {
                ...prev[updatedRow.id],
                adjusted_proj: updatedRow.Proj,
                StdDev: updatedRow.StdDev,
                Olimit: updatedRow.Olimit,
            }
        }));

        // Recalculate ptsValue
        if (updatedRow.Proj && updatedRow.Salary) {
            updatedRow.ptsValue = Number((updatedRow.Proj / (updatedRow.Salary / 1000)).toFixed(2));
        }

        // Update the players state
        setPlayers(prevPlayers =>
            prevPlayers.map(player =>
                player.id === updatedRow.id ? { ...player, ...updatedRow } : player
            )
        );

        return updatedRow;
    };

    const applyGlobalSettings = (positionSettings: { [key: string]: number }) => {
        setPlayers(prevPlayers =>
            prevPlayers.map(player => ({
                ...player,
                Olimit: positionSettings[player.Pos] || player.Olimit
            }))
        );
    };



    // useEffect(() => {
    //     console.log('Selected rows updated:', selectedRows);
    // }, [selectedRows]);

    // useEffect(() => {
    //     console.log('All players updated:', allPlayers);
    // }, [allPlayers]);





    if (!isLoggedIn) {
        return (
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: 'calc(100vh - 64px)', // Adjust this if your app bar height is different
                p: 3,
                textAlign: 'center'
            }}>
                <Typography variant="h4" gutterBottom>
                    Welcome to the DFS OS
                </Typography>
                <Typography variant="h5" color="error" sx={{ mb: 2 }}>
                    You need to be logged in to use this feature
                </Typography>
                <Typography variant="body1">
                    Please use the login button in the top right corner to access all features.
                </Typography>
                <Typography variant="body2" sx={{ mt: 2, fontStyle: 'italic' }}>
                    If you don't have an account yet, you can sign up for free!
                </Typography>
            </Box>
        );
    }

    return (
        <Box sx={{
            width: '100%',
            maxWidth: isShowdown ? '100%' : { xs: '100%', sm: '90%', md: '90%', lg: '90%' },
            marginLeft: 0,
            marginRight: isShowdown ? 0 : 'auto',
            overflowX: 'hidden',
            pt: 1
        }}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Card elevation={3}>
                        <CardContent sx={{ p: 1 }}>
                            <Grid container spacing={1} alignItems="flex-start">
                                <Grid item xs={12} md={3}>
                                    <Typography variant="subtitle2" gutterBottom>Positions</Typography>
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                        <Chip
                                            key="ALL"
                                            label="ALL"
                                            onClick={() => handlePositionToggle('ALL')}
                                            color={selectedPositions.length === positions.length ? 'primary' : 'default'}
                                            size="small"
                                        />
                                        {positions.map(position => (
                                            <Chip
                                                key={position}
                                                label={position}
                                                onClick={() => handlePositionToggle(position)}
                                                color={selectedPositions.includes(position) ? 'primary' : 'default'}
                                                size="small"
                                            />
                                        ))}
                                    </Box>
                                </Grid>

                                <Grid item xs={12} md={7}>
                                    <Typography variant="subtitle2" gutterBottom>Games</Typography>
                                    <Box sx={{ display: 'flex', flexWrap: 'nowrap', overflowX: 'auto', gap: 0.5, pb: 1 }}>
                                        {games.length > 1 && (
                                            <Chip
                                                key="ALL"
                                                label="ALL"
                                                onClick={() => handleTeamToggle('ALL')}
                                                color={selectedTeams.length === games.length * 2 ? 'primary' : 'default'}
                                                size="small"
                                            />
                                        )}
                                        {games.map(game => (
                                            <GameDisplay
                                                key={game.id}
                                                game={game}
                                                selectedTeams={selectedTeams}
                                                onTeamToggle={handleTeamToggle}
                                            />
                                        ))}
                                    </Box>
                                </Grid>

                                <Grid item xs={12} md={2}>
                                    <Typography variant="subtitle2" gutterBottom>Filters</Typography>
                                    <Grid container spacing={1}>
                                        <Grid item xs={6}>
                                            <TextField label="Min Proj" size="small" value={minProj} onChange={handleMinProjChange} fullWidth />
                                            <TextField label="Min Own" size="small" value={minOwn} onChange={handleMinOwnChange} fullWidth sx={{ mt: 1 }} />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField label="Max Own" size="small" value={maxOwn} onChange={handleMaxOwnChange} fullWidth />
                                            <TextField label="Min Value" size="small" value={minValue} onChange={handleMinValueChange} fullWidth sx={{ mt: 1 }} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={12}>
                    <Card elevation={3}>
                        <CardContent>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1, flexWrap: 'wrap', gap: 1 }}>
                                <TextField
                                    label="Number of Lineups"
                                    type="number"
                                    value={numberOfLineups}
                                    onChange={handleLineupNumberChange}
                                    size="small"
                                    sx={{ width: 150 }}
                                    error={!!lineupError}
                                    helperText={lineupError}
                                />
                                <Button
                                    onClick={handleBuildLineups}
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                >
                                    BUILD LINEUPS
                                </Button>
                                <TextField
                                    label="Search Players"
                                    variant="outlined"
                                    size="small"
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                    sx={{ width: { xs: '100%', sm: '25%' } }}
                                />
                                <Box>
                                    <Tooltip title="Coming Soon!">
                                        <Chip
                                            icon={<CloudUploadIcon />}
                                            label="Upload Projections"
                                            size="small"
                                            sx={{ mr: 1 }}
                                        />
                                    </Tooltip>
                                    <Chip
                                        icon={<DownloadIcon />}
                                        label="Download Players"
                                        onClick={handleDownloadCSV}
                                        color="primary"
                                        size="small"
                                    />
                                </Box>
                            </Box>

                            <Paper style={{ height: 400, width: '100%', overflowX: 'auto' }}>
                                {players.length > 0 ? (
                                    <DataGrid
                                        key={players.length}
                                        rows={allPlayers}
                                        columns={columns}
                                        initialState={{
                                            pagination: {
                                                paginationModel: { pageSize: 50, page: 0 },
                                            },
                                        }}
                                        rowHeight={30}
                                        pageSizeOptions={[50, 75, 100]}
                                        checkboxSelection
                                        disableRowSelectionOnClick
                                        onCellEditStop={handleCellEdit}
                                        processRowUpdate={processRowUpdate}
                                        onProcessRowUpdateError={handleProcessRowUpdateError}
                                        sx={{
                                            '& .editable-cell': editableCellStyle,
                                            '& .MuiDataGrid-columnHeaders': {
                                                position: 'sticky',
                                                top: 0,
                                                zIndex: 1,
                                                height: 55,
                                                backgroundColor: '#f5f5f5',
                                                fontWeight: 'bold',
                                            },
                                            '& .MuiDataGrid-row:nth-of-type(odd)': {
                                                backgroundColor: '#f9f9f9',
                                            },
                                            '& .MuiDataGrid-row:nth-of-type(even)': {
                                                backgroundColor: '#ffffff',
                                            },
                                            '& .MuiDataGrid-row:hover': {
                                                backgroundColor: '#e0e0e0',
                                            },
                                            '& .MuiDataGrid-columnSeparator': {
                                                display: 'none',
                                            },
                                        }}
                                        isRowSelectable={() => true}
                                        rowSelectionModel={selectedRows}
                                        onRowSelectionModelChange={handleRowSelectionModelChange}
                                        getRowId={(row) => row.id}
                                    />
                                ) : (
                                    <Typography sx={{ p: 2 }}>No players available for the selected slate.</Typography>
                                )}
                            </Paper>
                        </CardContent>
                    </Card>
                </Grid>

                {currentJobId && lineups.length > 0 && (
                    <Grid item xs={12}>
                        <Card elevation={3}>
                            <CardContent>
                                <Typography variant="h6" gutterBottom>
                                    Lineups ({lineups.length}/{numberOfLineups})
                                </Typography>
                                <LinearProgress
                                    variant="determinate"
                                    value={(lineups.length / numberOfLineups) * 100}
                                    sx={{ height: 10, borderRadius: 5, mb: 2 }}
                                />
                                <Button
                                    onClick={downloadCSVlu}
                                    variant="contained"
                                    color="primary"
                                    disabled={lineups.length === 0}
                                >
                                    Download Lineups CSV
                                </Button>
                            </CardContent>
                        </Card>
                    </Grid>
                )}

                {isOptimizerStarting && lineups.length === 0 && (
                    <Grid item xs={12}>
                        <Card elevation={3}>
                            <CardContent>
                                <Box display="flex" alignItems="center" justifyContent="center">
                                    <CircularProgress size={36} sx={{ mr: 2 }} />
                                    <Typography>Firing up the optimizer...</Typography>
                                    <Typography variant="body1" sx={{ color: 'red', fontWeight: 'bold' }}>
                                        The Beta Test is free, so this might be a little slow to start on our cheap servers
                                    </Typography>
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>
                )}

                {warnings.length > 0 && (
                    <Grid item xs={12}>
                        <Alert severity="warning" sx={{ mt: 2 }}>
                            {warnings.length} lineup(s) could not be generated due to restrictive settings.
                            <ul>
                                {warnings.map((warning, index) => (
                                    <li key={index}>{warning}</li>
                                ))}
                            </ul>
                        </Alert>
                    </Grid>
                )}

                <Grid item xs={12} md={isSmallScreen ? 12 : 8}>
                    <Card elevation={3}>
                        <CardContent>
                            <Typography variant="h6" gutterBottom>Lineups</Typography>
                            <LineupTable lineups={lineups} sport={sport as 'MLB' | 'NFL'} isShowdown={isShowdown} />
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} md={isSmallScreen ? 12 : 4}>
                    <Card elevation={3}>
                        <CardContent>
                            <Typography variant="h6" gutterBottom>Player Exposures</Typography>
                            <PlayerExposureTable playerExposures={playerExposureList} sport={sport as 'MLB' | 'NFL'} isShowdown={isShowdown} />
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Box>
    );
}, (prevProps, nextProps) => {
    console.log('PlayerPool props comparison:', {
        prevProps,
        nextProps,
        areEqual: prevProps.numberOfLineups === nextProps.numberOfLineups &&
            JSON.stringify(prevProps.stackingInfo) === JSON.stringify(nextProps.stackingInfo) &&
            prevProps.updatePersistentState === nextProps.updatePersistentState
    });
    return prevProps.numberOfLineups === nextProps.numberOfLineups &&
        JSON.stringify(prevProps.stackingInfo) === JSON.stringify(nextProps.stackingInfo) &&
        prevProps.updatePersistentState === nextProps.updatePersistentState;
});

export default PlayerPool;