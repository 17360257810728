import React, { useMemo } from 'react';
import { Box } from '@mui/material';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';

interface Player {
    playerId: string;
    position: string;
    projection: number;
    salary: number;
}

interface Lineup {
    lineupId: string;
    totalProjection: number;
    totalSalary: number;
    players: Player[];
}

interface LineupTableProps {
    lineups: Lineup[];
    sport: 'MLB' | 'NFL';
    isShowdown: boolean;
}

const LineupTable: React.FC<LineupTableProps> = React.memo(({ lineups, sport, isShowdown }) => {
    console.log('LineupTable rendering', lineups.length);

    const processMLBLineup = (lineup: Lineup): (Player | null)[] => {
        const processedPlayers: (Player | null)[] = new Array(10).fill(null);
        const positionCounts: { [key: string]: number } = {};
        const positionOrder = ['P', 'P', 'C', '1B', '2B', '3B', 'SS', 'OF', 'OF', 'OF'];

        lineup.players.forEach(player => {
            const positionIndex = positionOrder.indexOf(player.position);
            if (positionIndex !== -1) {
                const count = positionCounts[player.position] || 0;
                let availableIndex = -1;

                if (player.position === 'OF') {
                    // For OF, find the next available OF slot
                    availableIndex = positionOrder.findIndex((pos, index) => pos === 'OF' && !processedPlayers[index]);
                } else {
                    availableIndex = positionOrder.indexOf(player.position, positionIndex + count);
                }

                if (availableIndex !== -1 && !processedPlayers[availableIndex]) {
                    processedPlayers[availableIndex] = player;
                    positionCounts[player.position] = (positionCounts[player.position] || 0) + 1;
                }
            }
        });

        return processedPlayers;
    };

    const processNFLLineup = (lineup: Lineup): (Player | null)[] => {
        const processed: { [key: string]: Player[] } = {
            QB: [], RB: [], WR: [], TE: [], DST: [], FLEX: []
        };

        lineup.players.forEach(player => {
            if (['RB', 'WR', 'TE'].includes(player.position)) {
                if (processed[player.position].length < (player.position === 'TE' ? 1 : (player.position === 'RB' ? 2 : 3))) {
                    processed[player.position].push(player);
                } else {
                    processed.FLEX.push(player);
                }
            } else {
                processed[player.position].push(player);
            }
        });

        return [
            processed.QB[0] || null,
            processed.RB[0] || null,
            processed.RB[1] || null,
            processed.WR[0] || null,
            processed.WR[1] || null,
            processed.WR[2] || null,
            processed.TE[0] || null,
            processed.FLEX[0] || null,
            processed.DST[0] || null
        ];
    };

    const processShowdownLineup = (lineup: Lineup): (Player | null)[] => {
        const processed: (Player | null)[] = new Array(6).fill(null);
        const captain = lineup.players.find(p => p.position === 'CPT');
        if (captain) processed[0] = captain;
        const flexPlayers = lineup.players.filter(p => p.position === 'FLEX');
        flexPlayers.forEach((player, index) => {
            if (index < 5) processed[index + 1] = player;
        });
        return processed;
    };

    const columns: GridColDef[] = useMemo(() => {
        const baseColumns: GridColDef[] = [
            { field: 'lineupId', headerName: 'Lineup ID', width: 80 },
            { field: 'totalProjection', headerName: 'Total Projection', width: 100 },
            { field: 'totalSalary', headerName: 'Total Salary', width: 100 },
        ];

        let positionColumns: GridColDef[];
        if (isShowdown) {
            positionColumns = ['CPT', 'FLEX', 'FLEX', 'FLEX', 'FLEX', 'FLEX'].map((pos, index) => ({
                field: `player_${index}`,
                headerName: pos,
                width: 100,
                renderCell: (params: GridRenderCellParams) => {
                    const player = params.value as Player | null;
                    return player ? `${player.playerId}` : '';
                },
            }));
        } else if (sport === 'NFL') {
            positionColumns = ['QB', 'RB', 'RB', 'WR', 'WR', 'WR', 'TE', 'FLEX', 'DST'].map((pos, index) => ({
                field: `player_${index}`,
                headerName: pos,
                width: 100,
                renderCell: (params: GridRenderCellParams) => {
                    const player = params.value as Player | null;
                    return player ? `${player.playerId}` : '';
                },
            }));
        } else {
            positionColumns = ['P', 'P', 'C', '1B', '2B', '3B', 'SS', 'OF', 'OF', 'OF'].map((pos, index) => ({
                field: `player_${index}`,
                headerName: pos,
                width: 100,
                renderCell: (params: GridRenderCellParams) => {
                    const player = params.value as Player | null;
                    return player ? `${player.playerId}` : '';
                },
            }));
        }

        return [...baseColumns, ...positionColumns];
    }, [sport, isShowdown]);

    const rows = useMemo(() => {
        return lineups.map(lineup => {
            let processedPlayers: (Player | null)[];
            if (isShowdown) {
                processedPlayers = processShowdownLineup(lineup);
            } else if (sport === 'NFL') {
                processedPlayers = processNFLLineup(lineup);
            } else {
                processedPlayers = processMLBLineup(lineup);
            }

            return {
                id: lineup.lineupId,
                lineupId: lineup.lineupId,
                totalProjection: lineup.totalProjection,
                totalSalary: lineup.totalSalary,
                ...processedPlayers.reduce((acc, player, index) => {
                    acc[`player_${index}`] = player;
                    return acc;
                }, {} as Record<string, Player | null>)
            };
        });
    }, [lineups, sport, isShowdown]);

    return (
        <Box sx={{ height: 400, width: '100%' }}>
            <DataGrid
                rows={rows}
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: { pageSize: 50, page: 0 },
                    },
                }}
                pageSizeOptions={[50, 75, 100]}
                disableRowSelectionOnClick
            />
        </Box>
    );
});

export default LineupTable;