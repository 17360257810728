import React, { useState, useMemo, useCallback } from 'react';
import { Box, Tab, Tabs, Typography, Paper, Container } from '@mui/material';
import { styled } from '@mui/material/styles';
import PlayerPool from './LineupOptimizer/PlayerPool';
import Settings from './LineupOptimizer/Settings';
import Stacking from './LineupOptimizer/Stacking';
import ShowdownSettings from './ShowdownSettings';
import { SettingsProvider } from '../context/SettingsContext';
import { useSlateContext } from '../context/SlateContext';
import { PersistentStateProvider, usePersistentState } from '../context/PersistentStateContext';
import { NFLTeamStack } from '../types';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

const StyledTabs = styled(Tabs)(({ theme }) => ({
    '& .MuiTab-root': {
        textTransform: 'none',
        minWidth: 72,
        fontSize: '1.1rem',
        fontWeight: theme.typography.fontWeightRegular,
        marginRight: theme.spacing(6),
        '&:hover': {
            color: theme.palette.secondary.main,
            opacity: 1,
        },
        '&.Mui-selected': {
            color: theme.palette.secondary.main,
            fontWeight: theme.typography.fontWeightMedium,
        },
    },
    '& .MuiTabs-indicator': {
        height: 5,
        borderTopLeftRadius: 6,
        borderTopRightRadius: 6,
    },
}));

function LineupOptimizer() {
    const [value, setValue] = useState(0);
    const { slateId, slateType } = useSlateContext();
    const { persistentState, updatePersistentState } = usePersistentState();
    const { stackingInfo, numberOfLineups } = persistentState;
    const memoizedNFLTeamStacks = useMemo(() => stackingInfo.nflTeamStacks, [stackingInfo.nflTeamStacks]);
    const [nflTeamStacks, setNFLTeamStacks] = useState<NFLTeamStack[]>(memoizedNFLTeamStacks);
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };
    console.log('LineupOptimizer rendering', { value, slateId, slateType, numberOfLineups });

    const memoizedStackingInfo = useMemo(() => stackingInfo, [stackingInfo]);

    const memoizedUpdatePersistentState = useCallback((updates: Partial<any>) => {
        updatePersistentState(updates);
    }, [updatePersistentState]);

    const isShowdown = slateType === 'Showdown';


    return (
        <SettingsProvider>
            <Container maxWidth="xl" disableGutters>
                <Box sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    pb: 12,
                    pt: 12
                }}>
                    <Box sx={{
                        width: '100%',
                        mb: 1
                    }}>
                        <StyledTabs
                            value={value}
                            onChange={handleChange}
                            aria-label="lineup optimizer tabs"
                            sx={{
                                position: 'sticky',
                                top: 64,
                                zIndex: 1,
                                backgroundColor: 'transparent',
                                boxShadow: 'none',
                            }}
                        >
                            <Tab label="Player Pool" />
                            <Tab label="Settings" />
                            {!isShowdown && <Tab label="Stacking" />}
                        </StyledTabs>
                    </Box>
                    <Box sx={{ width: '100%' }}>
                        <TabPanel value={value} index={0}>
                            <PlayerPool
                                stackingInfo={memoizedStackingInfo}
                                numberOfLineups={numberOfLineups}
                                updatePersistentState={memoizedUpdatePersistentState}
                                isShowdown={isShowdown}
                            />
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            {isShowdown ? (
                                <ShowdownSettings
                                    updatePersistentState={updatePersistentState}
                                    settings={persistentState}
                                />
                            ) : (
                                <Settings />
                            )}
                        </TabPanel>
                        {!isShowdown && (
                            <TabPanel value={value} index={2}>
                                <Stacking
                                    slateId={slateId}
                                    nflTeamStacks={nflTeamStacks}
                                    setNFLTeamStacks={setNFLTeamStacks}
                                />
                            </TabPanel>
                        )}
                    </Box>
                </Box>
            </Container>
        </SettingsProvider>
    );
}

const MemoizedLineupOptimizer = React.memo(LineupOptimizer);

export default function WrappedLineupOptimizer() {
    return (
        <PersistentStateProvider>
            <MemoizedLineupOptimizer />
        </PersistentStateProvider>
    );
}