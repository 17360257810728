import { createTheme, alpha } from '@mui/material/styles';

const baseTheme = createTheme({
    palette: {
        primary: {
            main: '#3f51b5',
            light: '#757de8',
            dark: '#002984',
        },
        secondary: {
            main: '#dc5f00',
            light: '#ff4081',
            dark: '#c51162',
        },
        background: {
            default: '#f5f5f5',
            paper: '#ffffff',
        },
    },
});

const theme = createTheme({
    ...baseTheme,
    typography: {
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontSize: 12,
        h1: { fontSize: '1.5rem', fontWeight: 500 },
        h2: { fontSize: '1.25rem', fontWeight: 500 },
        h3: { fontSize: '1.167rem', fontWeight: 500 },
        h4: { fontSize: '1.083rem', fontWeight: 500 },
        h5: { fontSize: '1rem', fontWeight: 500 },
        h6: { fontSize: '0.917rem', fontWeight: 500 },
    },
    shape: {
        borderRadius: 8,
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    fontSize: '0.917rem',
                    textTransform: 'none',
                    fontWeight: 500,
                    boxShadow: 'none',
                    '&:hover': {
                        boxShadow: '0 2px 4px -1px rgba(0,0,0,0.2), 0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12)',
                    },
                },
                containedPrimary: {
                    backgroundColor: '#3f51b5',
                    '&:hover': {
                        backgroundColor: '#303f9f',
                    },
                },
                containedSecondary: {
                    backgroundColor: '#f50057',
                    '&:hover': {
                        backgroundColor: '#c51162',
                    },
                },
            },
        },
        MuiTabs: {
            styleOverrides: {
                indicator: {
                    backgroundColor: baseTheme.palette.secondary.main,
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    '&.Mui-selected': {
                        color: baseTheme.palette.secondary.main,
                    },
                },
            },
        },
        MuiRadio: {
            styleOverrides: {
                root: {
                    color: baseTheme.palette.secondary.main,
                    '&.Mui-checked': {
                        color: baseTheme.palette.secondary.main,
                    },
                },
            },
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    fontSize: '0.917rem',
                    padding: '8px 16px',
                    borderBottom: '1px solid rgba(224, 224, 224, 1)',
                },
                head: {
                    fontWeight: 600,
                    backgroundColor: alpha('#3f51b5', 0.05),
                },
            },
        },
        MuiSlider: {
            styleOverrides: {
                root: {
                    color: baseTheme.palette.secondary.main,
                },
                thumb: {
                    '&:hover, &.Mui-focusVisible': {
                        boxShadow: `0px 0px 0px 8px ${alpha(baseTheme.palette.secondary.main, 0.16)}`,
                    },
                    '&.Mui-active': {
                        boxShadow: `0px 0px 0px 14px ${alpha(baseTheme.palette.secondary.main, 0.16)}`,
                    },
                },
            },
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    boxShadow: '0 2px 4px -1px rgba(0,0,0,0.1), 0 4px 5px 0 rgba(0,0,0,0.07), 0 1px 10px 0 rgba(0,0,0,0.06)',
                    transition: 'box-shadow 0.3s ease-in-out',
                    '&:hover': {
                        boxShadow: '0 4px 8px -2px rgba(0,0,0,0.15), 0 6px 10px 0 rgba(0,0,0,0.1), 0 2px 15px 0 rgba(0,0,0,0.08)',
                    },
                },
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    '& .MuiInputBase-input': {
                        fontSize: '0.917rem',
                    },
                    '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                            borderColor: 'rgba(0, 0, 0, 0.23)',
                        },
                        '&:hover fieldset': {
                            borderColor: '#3f51b5',
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: '#3f51b5',
                        },
                    },
                },
            },
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    borderRadius: '16px',
                },
            },
        },
        // MuiDataGrid: {
        //     styleOverrides: {
        //         root: {
        //             border: 'none',
        //             '& .MuiDataGrid-cell:focus': {
        //                 outline: 'none',
        //             },
        //             '& .MuiDataGrid-row': {
        //                 maxHeight: '40px !important',
        //                 minHeight: '40px !important',
        //             },
        //             '& .MuiDataGrid-cell': {
        //                 maxHeight: '40px !important',
        //                 minHeight: '40px !important',
        //                 lineHeight: '40px !important',
        //                 fontSize: '0.917rem',
        //                 borderBottom: '1px solid rgba(224, 224, 224, 0.5)',
        //             },
        //             '& .MuiDataGrid-columnHeader': {
        //                 maxHeight: '40px !important',
        //                 minHeight: '40px !important',
        //                 lineHeight: '40px !important',
        //                 fontSize: '0.917rem',
        //                 fontWeight: 600,
        //                 backgroundColor: alpha('#3f51b5', 0.05),
        //             },
        //         },
        //     },
        // },
    },
});

export default theme;