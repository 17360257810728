import React, { useState } from 'react';
import { Dialog, DialogContent, DialogTitle, Button, Box, Typography, Divider } from '@mui/material';
import LoginForm from './LoginForm';
import SignupForm from './SignupForm';

interface AuthModalProps {
    open: boolean;
    onClose: () => void;
}

const AuthModal: React.FC<AuthModalProps> = ({ open, onClose }) => {
    const [isLogin, setIsLogin] = useState(true);
    const [authSuccess, setAuthSuccess] = useState(false);

    const handleAuthSuccess = () => {
        setAuthSuccess(true);
        setTimeout(() => {
            onClose();
            setAuthSuccess(false);
        }, 5000);
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle sx={{ textAlign: 'center', pb: 1 }}>
                <Typography variant="h5" fontWeight="bold">
                    {isLogin ? 'Welcome Back' : 'Join the Movement'}
                </Typography>
            </DialogTitle>
            <Divider />
            <DialogContent sx={{ py: 3 }}>
                {isLogin ? (
                    <LoginForm onSuccess={handleAuthSuccess} />
                ) : (
                    <SignupForm onSuccess={handleAuthSuccess} />
                )}
                {!authSuccess && (
                    <Box sx={{ mt: 3, textAlign: 'center' }}>
                        <Button
                            onClick={() => setIsLogin(!isLogin)}
                            sx={{ textTransform: 'none' }}
                        >
                            {isLogin ? "Don't have an account? Sign Up" : 'Already have an account? Login'}
                        </Button>
                    </Box>
                )}
            </DialogContent>
        </Dialog>
    );
};

export default AuthModal;