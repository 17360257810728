import React from 'react';
import ReactMarkdown from 'react-markdown';
import { Box, Typography, Paper, Divider } from '@mui/material';
import rehypeRaw from 'rehype-raw';
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';
import TuneIcon from '@mui/icons-material/Tune';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import HandymanIcon from '@mui/icons-material/Handyman';
import StadiumIcon from '@mui/icons-material/Stadium';

interface DocumentationSectionProps {
    title: string;
    icon: React.ReactNode;
    children: React.ReactNode;
}

const DocumentationSection: React.FC<DocumentationSectionProps> = ({ title, icon, children }) => (
    <Paper elevation={3} sx={{ padding: 3, marginBottom: 3 }}>
        <Typography variant="h5" gutterBottom sx={{ display: 'flex', alignItems: 'center', color: '#1976d2' }}>
            {icon}
            <span style={{ marginLeft: '10px' }}>{title}</span>
        </Typography>
        <Divider sx={{ marginBottom: 2 }} />
        {children}
    </Paper>
);

const Documentation = () => {
    return (
        <Box sx={{ maxWidth: 800, margin: 'auto', padding: 12 }}>
            <Typography variant="h4" gutterBottom sx={{ color: '#1976d2', textAlign: 'center' }}>
                DFS OS Documentation
            </Typography>

            <DocumentationSection title="Important Note" icon={<WarningIcon color="error" />}>
                <Typography variant="body1" sx={{ color: 'red', fontWeight: 'bold' }}>
                    Uncertain Edge and the DFS OS are in Beta testing. Check all of the outputs to validate and ensure you are getting the results you want. We fully expect bugs, hiccups, and general nonsense as we work through the kinks. Thanks for being a Beta Tester!
                </Typography>
            </DocumentationSection>

            <DocumentationSection title="General Navigation" icon={<InfoIcon color="primary" />}>
                <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                    {`
- The left hand sidebar provides intuitive navigation for the main pages of the DFS OS.
- The header allows for easy selection of the site, sports, date, and slate you are working on.
- The header also has easy sign up and login features.
          `}
                </ReactMarkdown>
            </DocumentationSection>

            <DocumentationSection title="Player Stats and Stacks" icon={<AutoGraphIcon color="primary" />}>
                <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                    {`
This page is the likely starting point for your research. We present easily digestible tables and charts showing the 3 main DFS OS metrics. These borrow concepts from baseball's "plus metrics", where 100 is an average score, 150 would represent a score 50% higher than average, and 50 would represent a score 50% below average. All of these metrics are derived from intensive, slate-specific simulations using our smart player correlations.

- **Cash Score+**: A measure of consistency for a particular player or stack, typical of the types of plays that cash game players favor.
- **Upside Score+**: A measure of the potential upside for a particular player or stack. Boom or Bust, barbell approach, you've heard this discussed in the DFS world. If you are a GPP player then taking a riskier, higher upside play may be what you are looking for.
- **Leverage Score+**: A measure of consistency and upside while incorporating game theory. This aims to measure a player or stack's potential against their projected ownership.
          `}
                </ReactMarkdown>
            </DocumentationSection>

            <DocumentationSection title="Lineup Optimizer" icon={<HandymanIcon color="primary" />}>
                <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                    {`
The heart of the DFS OS. Simulations are important but you need great lineups in your pool for success.

## Player Pool Tab

Displays the players for the selected slate. All of the projections here are model based, including the Std Dev. The Std Dev here is a measure of the range of projections for the given player
- The table can be sorted and filtered, and the projections and ownership projections can be edited.
- Clicking a Position or Team from the respective menu will add/remove that Position or Team from the player pool.
- Input boxes allow you to filter by projection, ownership projection, and Value (fantasy point projection / (Salary/$1k)).
- Checkboxes for each player allow you to include/exclude players from your pool.
- The data can be downloaded as a csv.

**Note**: Edits made here will flow through the lineup building step.

After lineups have been built, they will appear below the Player Pool in a sortable and filterable table. You can export these lineups to a csv for easy uploading to your preferred site.

## Settings Tab

Your main hub for manipulating the lineup building strategy. Available inputs include:

- **Number of lineups** - how many lineups you want the optimizer to build for you
- **Salary Max and Min** - total salary used in a lineup. Set a max to prevent the optimizer from using the full salary, or set a minimum to ensure most of the salary cap is used. 
- **Ownership Max and Min** - total sum ownership projection used in a lineup.
- **Force Low Owned Players** - check this box to force the optimizer to select lower owned players. You will then be given input boxes to determine a low ownership threshold (below this players are considered "low owned"), and the number of low owned players that need to be in each lineup. 
- **Randomness, Distributions, and Smart Correlations** - choose one of these options. 
    - Using randomness will apply a flat +/- percentage to each players projection. 
    - Using player distributions will use our vast historical database to calculate sport-position-player specific distributions instead of pure randomness. 
    - Using Smart Correlations takes this all a step further. This option uses the player distributions, but also applies Team-Position level correlations to the entire process. **This is the most advanced, and most game realistic method of building lineups**

## Stacking Tab

Used to control acceptable Stacks for your lineups:

- Checkboxes allow you to determine which Teams should be included as primary or secondary stacks.
- Input boxes allow you to provide how large each stack should be.
- Depending on the sport, you may also see Team Stack specific information, like batting order in Baseball.

**Note**: Carefully think through your settings and stacking strategy to avoid situations where few or no lineups can be built.

**Note**: Primary Stacks will always be larger than or equal to Secondary stacks. For example, If you are building MLB lineups for DraftKings and set both Stack Size inputs to 5, then the optimizer will force this to be 5 - 3 since only 8 Hitters are allowed. 
          `}
                </ReactMarkdown>
            </DocumentationSection>

            <DocumentationSection title="Simulator" icon={<StadiumIcon color="primary" />}>
                <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                    {`
Our simulation engine is an advanced approach that simulates players, games, lineups, contests, and your opponents. It provides a view of potential lineup outcomes given the underlying data models.

## Uploading Lineups

- You can upload a csv of lineups if you choose to use a different tool or hand build lineups.
- Ensure the csv has 1 row per lineup, and one column per position.
- Position headers should be exactly as the site you are using displays them and in the order it displays them.

**Note**: To avoid naming mismatches, it's best to download a csv of our Player Pool so you have the expected names of each Player.

## Simulation Options

- Randomness, Distributions, and Smart Correlations: See Settings Tab above for desctiptions of these ooptions
- Contest Size: Use these options to set the size of the contest you want to simulate for.

## Simulation Results

After simulating, you'll see a table outlining the results. Metrics include:

### Core Metrics

-**Win %**: how often the lineup won the simulated contests

-**Top 1%**: how often the lineup finished in the top 1% of all of the lineups in the simulated contests

-**Top 10%**: how often the lineup finished in the top 10% of all of the lineups in the simulated contests

-**Cash %**: for a given contest size payout structure, how often the lineup finished with a positive return for the simulated contests

### Advanced Metrics

-**Win Leverage**: a game theory approach that quantifies the ratio of simulated wins to projected ownership. This can be useful for GPPs

-**Variance**: simulation result variance. Over the course of the simulation process, this is a measure of how the lineups scores vary compared to their mean. This can be helpful to understand lineup consistency.

-**sROI**: simulated ROI. For a given contest size payout structure, this metric shows the average profit/loss from the simulated contests

-**UEscore**: this metric uses our proprietary machine learning model, and attempts to further quantify the potential ROI of each lineup. sROI is extremely useful, but UEscore takes into account each out of each simulation run as inputs to our model. The goal of this model is to find high GPP finishers, and it is not recommended for cash game or small field contests. 

More metrics are currently in alpha testing and will be added in the future.
          `}
                </ReactMarkdown>
            </DocumentationSection>
        </Box>
    );
};

export default Documentation;