// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyB3NLWLyfUbEArJlAQMcrJB__yEhWpyCjk",
    authDomain: "uncertain-edge.firebaseapp.com",
    projectId: "uncertain-edge",
    storageBucket: "uncertain-edge.appspot.com",
    messagingSenderId: "450591700064",
    appId: "1:450591700064:web:57d0841a360663a979ff05",
    measurementId: "G-MMGJ92YK78"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const db = getFirestore(app);