import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import theme from './theme';
import Box from '@mui/material/Box';
import { SlateProvider, useSlateContext } from './context/SlateContext';
import Header from './components/Header';
import Sidebar from './components/Sidebar';
import Home from './components/Home';
import PlayerStats from './components/PlayerStats';
import LineupOptimizer from './components/LineupOptimizer';
import ContestSimulator from './components/ContestSimulator';
import Documentation from './components/Documentation';
import { AuthProvider } from './context/AuthContext';
import { SimulationResults } from './types';

function AppContent() {
    const [simulationResults, setSimulationResults] = useState<SimulationResults | null>(null);
    const [resetKey, setResetKey] = useState(0);
    const { sport } = useSlateContext();

    useEffect(() => {
        const storedResults = sessionStorage.getItem('simulationResults');
        if (storedResults) {
            setSimulationResults(JSON.parse(storedResults));
        }
    }, []);

    useEffect(() => {
        // Reset when sport changes
        setResetKey(prevKey => prevKey + 1);
        setSimulationResults(null);
        sessionStorage.removeItem('simulationResults');
    }, [sport]);

    const updateSimulationResults = (results: SimulationResults) => {
        setSimulationResults(results);
        sessionStorage.setItem('simulationResults', JSON.stringify(results));
    };

    return (
        <Box sx={{ display: 'flex' }}>
            <Header />
            <Sidebar />
            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/player-stats-and-stacks" element={<PlayerStats key={resetKey} />} />
                    <Route path="/lineup-optimizer" element={<LineupOptimizer key={resetKey} />} />
                    <Route
                        path="/contest-simulator"
                        element={
                            <ContestSimulator
                                key={resetKey}
                                results={simulationResults}
                                updateResults={updateSimulationResults}
                            />
                        }
                    />
                    <Route path="/documentation" element={<Documentation />} />
                </Routes>
            </Box>
        </Box>
    );
}

function App() {
    return (
        <AuthProvider>
            <SlateProvider>
                <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <Router>
                        <AppContent />
                    </Router>
                </ThemeProvider>
            </SlateProvider>
        </AuthProvider>
    );
}

export default App;