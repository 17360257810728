import React, { useState, useMemo } from 'react';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { Tooltip, IconButton, Box, Table, TableBody, TableCell, TableRow } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

interface LineupResultsTableProps {
    data: any[];
    sport: 'MLB' | 'NFL';
    isShowdown: boolean;
}

const LineupResultsTable: React.FC<LineupResultsTableProps> = React.memo(({ data, sport, isShowdown }) => {
    console.log('LineupResultsTable rendering', data.length);

    const columns: GridColDef[] = useMemo(() => {
        const baseColumns: GridColDef[] = [
            { field: 'lineup_id', headerName: 'ID', width: 70 },
            {
                field: 'win_pct', headerName: 'Win %', width: 80,
                renderCell: (params) => params.value != null ? `${(params.value).toFixed(2)}%` : '',
            },
            {
                field: 'top1p', headerName: 'Top 1%', width: 80,
                renderCell: (params) => params.value != null ? `${(params.value).toFixed(2)}%` : '',
            },
            {
                field: 'top10p', headerName: 'Top 10%', width: 80,
                renderCell: (params) => params.value != null ? `${(params.value).toFixed(2)}%` : '',
            },
            {
                field: 'cash_pct', headerName: 'Cash %', width: 80,
                renderCell: (params) => params.value != null ? `${(params.value).toFixed(2)}%` : '',
            },
            {
                field: 'roi', headerName: 'ROI', width: 80,
                renderCell: (params) => params.value != null ? `${(params.value).toFixed(2)}%` : '',
            },
            {
                field: 'UEscore', headerName: 'UE Score', width: 80,
                renderCell: (params) => params.value != null ? `${(params.value).toFixed(2)}` : '',
            },
            {
                field: 'win_leverage', headerName: 'Win Leverage', width: 80,
                renderCell: (params) => params.value != null ? `${(params.value).toFixed(2)}%` : '',
            },
        ];

        // Add Stack and Stack Size columns only for non-Showdown slates
        if (!isShowdown) {
            baseColumns.splice(1, 0,
                { field: 'main_stack', headerName: 'Stack', width: 80 },
                { field: 'stack_size', headerName: 'Stack Size', width: 70 }
            );
        }


        let positionColumns: GridColDef[];
        if (isShowdown) {
            positionColumns = ['CPT1', 'FLEX1', 'FLEX2', 'FLEX3', 'FLEX4', 'FLEX5'].map(pos => ({
                field: pos,
                headerName: pos,
                width: 120,
                renderCell: (params: GridRenderCellParams) => params.value || '',
            }));
        } else if (sport === 'NFL') {
            positionColumns = ['QB1', 'RB1', 'RB2', 'WR1', 'WR2', 'WR3', 'TE1', 'FLEX', 'DST1'].map(pos => ({
                field: pos,
                headerName: pos,
                width: 120,
                renderCell: (params: GridRenderCellParams) => params.value || '',
            }));
        } else {
            // MLB positions
            positionColumns = ['P1', 'P2', 'C1', '1B1', '2B1', '3B1', 'SS1', 'OF1', 'OF2', 'OF3'].map(pos => ({
                field: pos,
                headerName: pos,
                width: 120,
                renderCell: (params: GridRenderCellParams) => params.value || '',
            }));
        }

        return [...baseColumns, ...positionColumns];
    }, [sport, isShowdown]);

    const rows = useMemo(() => {
        return data.map(row => ({
            id: row.lineup_id,
            ...row
        }));
    }, [data]);

    return (
        <Box sx={{ height: 400, width: '100%' }}>
            <DataGrid
                rows={rows}
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: { pageSize: 50, page: 0 },
                    },
                }}
                pageSizeOptions={[50, 75, 10]}
                density="compact"
                disableColumnMenu
            />
        </Box>
    );
});

export default LineupResultsTable;