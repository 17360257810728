import React from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

interface StackFrequencyTableProps {
    data: any[];
}

const StackFrequencyTable: React.FC<StackFrequencyTableProps> = ({ data }) => {
    const columns: GridColDef[] = [
        { field: 'main_stack', headerName: 'Stack', width: 100 },
        {
            field: 'first_pct',
            headerName: 'Win%',
            width: 100,
            renderCell: (params) => {
                return params.value != null ? `${(params.value).toFixed(2)}%` : '';
            },
        },
        {
            field: 'top_5_pct',
            headerName: 'Top 5 Finish %',
            width: 120,
            renderCell: (params) => {
                return params.value != null ? `${(params.value).toFixed(2)}%` : '';
            },
        },
        {
            field: 'top_25_pct',
            headerName: 'Top 25 Finish %',
            width: 120,
            renderCell: (params) => {
                return params.value != null ? `${(params.value).toFixed(2)}%` : '';
            },
        },
        {
            field: 'avg_best_rank',
            headerName: 'Avg Best Finish',
            width: 100,
            renderCell: (params) => {
                return params.value != null ? Number(params.value).toFixed(2) : '';
            },
        },
    ];

    const rows = data
        .sort((a, b) => b['Avg Best Finish'] - a['Avg Best Finish'])
        .slice(0, 25)
        .map((row, index) => ({
            id: row.main_stack || `row-${index}`,
            ...row
        }));

    return (
        <div style={{ height: 300, width: '100%' }}>
            <DataGrid
                rows={rows}
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: { pageSize: 50, page: 0 },
                    },
                }}
                pageSizeOptions={[50, 75, 10]}
                density="compact"
            />
        </div>
    );
};

export default StackFrequencyTable;