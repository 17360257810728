import React, { useEffect } from 'react';
import { Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Checkbox, FormControlLabel, TextField, Card, CardContent, Switch, Grid, Tooltip, Chip } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { useSlateContext } from '../../context/SlateContext';
import { useAuth } from '../../context/AuthContext';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../../firebase';
import { usePersistentState } from '../../context/PersistentStateContext';
import { NFLTeamStack, Player, StackingInfo } from '../../types';

// interface Player {
//     id: string;
//     Order?: number;
//     Player: string;
//     teamAbbreviation: string;
//     position: string;
//     opponent: string;
//     playerPosition: string;
//     Proj: number;
//     Team: string;
// }

interface TeamStack {
    team: string;
    players: (Player | null)[];
    isPrimaryStack: boolean;
    isSecondaryStack: boolean;
}

interface StackingProps {
    slateId: number | null;
    nflTeamStacks: NFLTeamStack[];
    setNFLTeamStacks: React.Dispatch<React.SetStateAction<NFLTeamStack[]>>;
}

interface StackingProps {
    slateId: number | null;
    // nflTeamStacks: NFLTeamStack[];
    // setNFLTeamStacks: React.Dispatch<React.SetStateAction<NFLTeamStack[]>>;
}

// interface StackingInfo {
//     primaryStacks: string[];
//     secondaryStacks: string[];
//     usePrimaryStacks: boolean;
//     useSecondaryStacks: boolean;
//     primaryStackCount: number | null;
//     secondaryStackCount: number | null;
//     useBringBack: boolean;
//     rbDstStack: boolean;
//     bringBackSize: number;
// }

function Stacking({ slateId, nflTeamStacks, setNFLTeamStacks }: StackingProps) {
    const { isLoggedIn } = useAuth();
    const { persistentState, updatePersistentState } = usePersistentState();
    const { stackingInfo } = persistentState as { stackingInfo: StackingInfo };
    const { sport } = useSlateContext();  // Add this line

    const [teamStacks, setTeamStacks] = React.useState<TeamStack[]>([]);
    // const [nflTeamStacks, setNFLTeamStacks] = React.useState<NFLTeamStack[]>(stackingInfo.nflTeamStacks);
    const [allQBStacksChecked, setAllQBStacksChecked] = React.useState(false);
    const [globalQBStackSize, setGlobalQBStackSize] = React.useState<number>(2);
    const [globalBringBackSize, setGlobalBringBackSize] = React.useState<number>(1);

    useEffect(() => {
        if (isLoggedIn && slateId) {
            fetchPlayers();
        }
    }, [isLoggedIn, slateId, sport]);

    useEffect(() => {
        // Update persistent state whenever nflTeamStacks changes
        updatePersistentState({
            stackingInfo: {
                ...stackingInfo,
                nflTeamStacks: nflTeamStacks,
            },
        });
    }, [nflTeamStacks]);


    const fetchPlayers = async () => {
        if (!slateId) return;
        try {
            const playersQuery = query(collection(db, 'data_players'), where('slate_identifier', '==', slateId));
            const playerSnapshot = await getDocs(playersQuery);
            const playerList = playerSnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            } as Player));

            if (sport === 'NFL') {
                const processedNFLTeamStacks = processNFLPlayersIntoTeamStacks(playerList);
                setNFLTeamStacks(processedNFLTeamStacks);
            }
            else if (sport === 'MLB') {
                const processedMLBTeamStacks = processPlayersIntoTeamStacks(playerList);
                setTeamStacks(processedMLBTeamStacks);
            }
        } catch (error) {
            console.error('Error fetching players:', error);
        }
    };

    const processPlayersIntoTeamStacks = (players: Player[]): TeamStack[] => {
        const teamMap = new Map<string, Player[]>();

        players.forEach(player => {
            const team = player.Team;
            if (!team) return;

            if (!teamMap.has(team)) {
                teamMap.set(team, []);
            }
            teamMap.get(team)?.push(player);
        });

        return Array.from(teamMap).map(([team, players]) => ({
            team,
            players: Array(9).fill(null).map((_, index) =>
                players.find(p => p.Order === index + 1) || null
            ),
            isPrimaryStack: stackingInfo.primaryStacks.includes(team),
            isSecondaryStack: stackingInfo.secondaryStacks.includes(team),
        }));
    };

    const processNFLPlayersIntoTeamStacks = (players: Player[]): NFLTeamStack[] => {
        const teamMap = new Map<string, Player[]>();
        const opponentMap = new Map<string, string>();

        players.forEach(player => {
            const team = player.teamAbbreviation || player.Team;
            const opponent = (player.opponent || player.Opp || '').replace(/^(vs |@ )/, '');
            if (!team || !opponent) return;

            if (!teamMap.has(team)) {
                teamMap.set(team, []);
                opponentMap.set(team, opponent);
            }
            teamMap.get(team)?.push(player);
        });

        return Array.from(teamMap).map(([team, teamPlayers]): NFLTeamStack => {
            const opponent = opponentMap.get(team) || '';
            const existingStack = stackingInfo.nflTeamStacks.find(s => s.team === team);

            return {
                team,
                opponent,
                players: teamPlayers,
                isPrimaryStack: existingStack?.isPrimaryStack || false,
                isSecondaryStack: existingStack?.isSecondaryStack || false,
                allPlayersIncluded: existingStack?.allPlayersIncluded || false,
                qbStackSize: existingStack?.qbStackSize || 2,
                bringBackSize: existingStack?.bringBackSize || 0,
                allTeammatesSelected: existingStack?.allTeammatesSelected || false,
                allOpponentsSelected: existingStack?.allOpponentsSelected || false,
                eligibleTeammates: existingStack?.eligibleTeammates || new Set(teamPlayers.filter(p => p.playerPosition === 'QB').map(p => p.Player)),
                eligibleOpponents: existingStack?.eligibleOpponents || new Set(),
            };
        });
    };

    const handleStackSizeChange = (index: number, type: 'qbStackSize' | 'bringBackSize', value: number) => {
        setNFLTeamStacks(prevStacks => {
            const newStacks = [...prevStacks];
            const stack = newStacks[index];

            if (type === 'bringBackSize') {
                // If there are selected opponents, ensure the minimum value is 1
                if (stack.eligibleOpponents.size > 0) {
                    value = Math.max(1, value);
                }
                stack[type] = Math.min(Math.max(0, value), 3);
            } else {
                stack[type] = Math.min(Math.max(1, value), 4);
            }

            return newStacks;
        });
    };

    const processNFLPlayers = (players: Player[]): Player[] => {
        const positionOrder = ['QB', 'RB', 'WR', 'TE'];
        return players.sort((a, b) => {
            const aPosition = a.playerPosition || a.Pos || '';
            const bPosition = b.playerPosition || b.Pos || '';
            const aIndex = positionOrder.indexOf(aPosition);
            const bIndex = positionOrder.indexOf(bPosition);
            return aIndex - bIndex;
        });
    };

    const handleCheckAllQBStacks = (event: React.ChangeEvent<HTMLInputElement>) => {
        const checked = event.target.checked;
        setAllQBStacksChecked(checked);
        setNFLTeamStacks(prevStacks => prevStacks.map(stack => ({
            ...stack,
            isPrimaryStack: checked,
            eligibleTeammates: checked
                ? new Set(stack.players.filter(p => p !== null && p.Proj > 3 && p.playerPosition !== 'DST').map(p => p!.Player))
                : new Set(stack.players[0] ? [stack.players[0].Player] : []),
            eligibleOpponents: checked
                ? new Set(nflTeamStacks.find(s => s.team === stack.opponent)?.players.filter(p => p !== null && p.Proj > 3 && p.playerPosition !== 'QB' && p.playerPosition !== 'DST').map(p => p!.Player) || [])
                : new Set(),
            allTeammatesSelected: checked,
            allOpponentsSelected: checked,
            qbStackSize: globalQBStackSize,
            bringBackSize: globalBringBackSize
        })));
        updatePersistentState({
            stackingInfo: {
                ...stackingInfo,
                primaryStacks: checked ? nflTeamStacks.map(stack => stack.team) : []
            }
        });
    };

    const handleStackingChange = (updates: Partial<typeof stackingInfo>) => {
        updatePersistentState({
            stackingInfo: { ...stackingInfo, ...updates },
        });
    };

    const handleGlobalQBStackSizeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newSize = Math.min(Math.max(1, Number(event.target.value)), 4);
        setGlobalQBStackSize(newSize);
        setNFLTeamStacks(prevStacks => prevStacks.map(stack => ({
            ...stack,
            qbStackSize: newSize
        })));
    };

    const handleGlobalBringBackSizeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newSize = Math.min(Math.max(0, Number(event.target.value)), 3);
        setGlobalBringBackSize(newSize);
        setNFLTeamStacks(prevStacks => prevStacks.map(stack => ({
            ...stack,
            bringBackSize: newSize
        })));
    };



    const handleStackToggle = (index: number, stackType: 'primary' | 'secondary') => {
        if (sport === 'NFL') {
            setNFLTeamStacks(prevStacks => {
                const newStacks = prevStacks.map((stack, i) => {
                    if (i === index) {
                        const newStack = {
                            ...stack,
                            isPrimaryStack: stackType === 'primary' ? !stack.isPrimaryStack : stack.isPrimaryStack,
                            isSecondaryStack: stackType === 'secondary' ? !stack.isSecondaryStack : stack.isSecondaryStack
                        };

                        // If unchecking the QB Stack, deselect all chips except QB
                        if (stackType === 'primary' && !newStack.isPrimaryStack) {
                            newStack.eligibleTeammates = new Set(stack.players[0] ? [stack.players[0].Player] : []);
                            newStack.eligibleOpponents = new Set();
                        }

                        return newStack;
                    }
                    return stack;
                });

                const updatedStackingInfo = {
                    ...stackingInfo,
                    primaryStacks: newStacks.filter(stack => stack.isPrimaryStack).map(stack => stack.team),
                    secondaryStacks: newStacks.filter(stack => stack.isSecondaryStack).map(stack => stack.team),
                };
                handleStackingChange(updatedStackingInfo);

                return newStacks;
            });
        } else {
            setTeamStacks(prevStacks => {
                const newStacks = prevStacks.map((stack, i) =>
                    i === index
                        ? {
                            ...stack,
                            isPrimaryStack: stackType === 'primary' ? !stack.isPrimaryStack : stack.isPrimaryStack,
                            isSecondaryStack: stackType === 'secondary' ? !stack.isSecondaryStack : stack.isSecondaryStack
                        }
                        : stack
                );

                const updatedStackingInfo = {
                    ...stackingInfo,
                    primaryStacks: newStacks.filter(stack => stack.isPrimaryStack).map(stack => stack.team),
                    secondaryStacks: newStacks.filter(stack => stack.isSecondaryStack).map(stack => stack.team),
                };
                handleStackingChange(updatedStackingInfo);

                return newStacks;
            });
        }
    };

    const handleAllTeammatesToggle = (index: number) => {
        setNFLTeamStacks(prevStacks => {
            const newStacks = [...prevStacks];
            const stack = newStacks[index];
            const allTeammates = stack.players.filter(p => p !== null && p.playerPosition !== 'QB' && p.playerPosition !== 'DST' && p.Proj > 3).map(p => p!.Player);

            stack.allTeammatesSelected = !stack.allTeammatesSelected;
            if (stack.allTeammatesSelected) {
                stack.eligibleTeammates = new Set([...stack.eligibleTeammates, ...allTeammates]);
            } else {
                stack.eligibleTeammates = new Set(stack.players[0] ? [stack.players[0].Player] : []);
            }

            return newStacks;
        });
    };

    const handleAllOpponentsToggle = (index: number) => {
        setNFLTeamStacks(prevStacks => {
            const newStacks = [...prevStacks];
            const stack = newStacks[index];
            const opponentStack = newStacks.find(s => s.team === stack.opponent);
            const allOpponents = opponentStack?.players.filter(p => p !== null && p.playerPosition !== 'QB' && p.playerPosition !== 'DST' && p.Proj > 3).map(p => p!.Player) || [];

            stack.allOpponentsSelected = !stack.allOpponentsSelected;
            if (stack.allOpponentsSelected) {
                stack.eligibleOpponents = new Set(allOpponents);
            } else {
                stack.eligibleOpponents = new Set();
            }

            return newStacks;
        });
    };


    const handleNFLStackToggle = (index: number, stackType: 'primary' | 'secondary') => {
        setNFLTeamStacks(prevStacks => {
            const newStacks = [...prevStacks];
            const stack = newStacks[index];
            const newIsPrimaryStack = stackType === 'primary' ? !stack.isPrimaryStack : stack.isPrimaryStack;

            stack.isPrimaryStack = newIsPrimaryStack;
            stack.isSecondaryStack = stackType === 'secondary' ? !stack.isSecondaryStack : stack.isSecondaryStack;

            if (newIsPrimaryStack) {
                stack.eligibleTeammates.add(stack.players[0]!.Player);
            } else {
                stack.eligibleTeammates = new Set(stack.players[0] ? [stack.players[0].Player] : []);
            }

            return newStacks;
        });

        const updatedStacks = nflTeamStacks.map((stack, i) =>
            i === index ? { ...stack, isPrimaryStack: stackType === 'primary' ? !stack.isPrimaryStack : stack.isPrimaryStack } : stack
        );
        handleStackingChange({
            primaryStacks: updatedStacks.filter(stack => stack.isPrimaryStack).map(stack => stack.team),
            secondaryStacks: updatedStacks.filter(stack => stack.isSecondaryStack).map(stack => stack.team),
        });
    };

    const handleAllPlayersToggle = (index: number) => {
        setNFLTeamStacks(prevStacks => {
            const newStacks = [...prevStacks];
            const stack = newStacks[index];
            stack.allPlayersIncluded = !stack.allPlayersIncluded;
            if (stack.allPlayersIncluded) {
                stack.eligibleTeammates = new Set(stack.players.filter(p => p !== null).map(p => p!.Player));
                stack.eligibleOpponents = new Set(nflTeamStacks.find(s => s.team === stack.opponent)?.players
                    .filter(p => p !== null && p.position !== 'QB-1')
                    .map(p => p!.Player) || []);
            } else {
                stack.eligibleTeammates = new Set(stack.players[0] ? [stack.players[0].Player] : []);
                stack.eligibleOpponents = new Set();
            }
            return newStacks;
        });
    };

    const handlePlayerToggle = (stackIndex: number, playerName: string, isOpponent: boolean) => {
        setNFLTeamStacks(prevStacks => {
            const newStacks = [...prevStacks];
            const stack = newStacks[stackIndex];
            const eligibleSet = isOpponent ? stack.eligibleOpponents : stack.eligibleTeammates;

            if (eligibleSet.has(playerName)) {
                eligibleSet.delete(playerName);
            } else {
                eligibleSet.add(playerName);
                // If an opponent is being added and the Bring Back Size is 0, set it to 1
                if (isOpponent && stack.bringBackSize === 0) {
                    stack.bringBackSize = 1;
                }
            }

            // Update allTeammatesSelected and allOpponentsSelected
            const opponentStack = newStacks.find(s => s.team === stack.opponent);
            if (opponentStack) {
                stack.allTeammatesSelected = stack.players.every(p =>
                    p === null ||
                    p.playerPosition === 'QB' ||
                    p.playerPosition === 'DST' ||
                    stack.eligibleTeammates.has(p.Player)
                );
                stack.allOpponentsSelected = opponentStack.players.every(p =>
                    p === null ||
                    p.playerPosition === 'QB' ||
                    p.playerPosition === 'DST' ||
                    stack.eligibleOpponents.has(p.Player)
                );
            }

            return newStacks;
        });
    };

    const renderOpponents = (stack: NFLTeamStack) => {
        console.log(`Rendering opponents for team: ${stack.team}, looking for opponent: ${stack.opponent}`);
        console.log('All available teams:', nflTeamStacks.map(s => s.team).join(', '));
        const opponentStack = nflTeamStacks.find(s => s.team === stack.opponent);
        console.log(`Opponent stack:`, opponentStack);
        if (!opponentStack) {
            console.log(`No opponent stack found for ${stack.opponent}`);
            return null;
        }

        const eligibleOpponents = opponentStack.players.filter(player =>
            player !== null &&
            player.Proj > 3 &&
            (player.playerPosition || player.Pos) !== 'QB' &&
            (player.playerPosition || player.Pos) !== 'DST'
        );
        console.log(`Eligible opponents:`, eligibleOpponents);

        return (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {eligibleOpponents.map((player) => {
                    const position = player.playerPosition || player.Pos || '';
                    return (
                        <Chip
                            key={`opponent-${stack.opponent}-${player.id}`}
                            label={player.Player}
                            onClick={() => handlePlayerToggle(nflTeamStacks.indexOf(stack), player.Player, true)}
                            color={stack.eligibleOpponents.has(player.Player) ? "primary" : "default"}
                            size="small"
                            disabled={!stack.isPrimaryStack}
                        />
                    );
                })}
            </Box>
        );
    };

    return (
        <Box sx={{ maxWidth: '90%', overflowX: 'auto' }}>
            <Card elevation={3} sx={{ mb: 1 }}>
                <CardContent>
                    <Typography variant="h6" gutterBottom>Stacking Options</Typography>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={12} sm={2}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={stackingInfo.usePrimaryStacks}
                                        onChange={(e) => handleStackingChange({ usePrimaryStacks: e.target.checked })}
                                        color="primary"
                                    />
                                }
                                label={sport === 'NFL' ? "Use QB Stacks" : "Use Primary Stacks"}
                            />
                        </Grid>
                        {sport === 'NFL' && (
                            <Grid item xs={12} sm={2}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={allQBStacksChecked}
                                            onChange={handleCheckAllQBStacks}
                                            color="primary"
                                        />
                                    }
                                    label="Add All Stacks"
                                />
                            </Grid>
                        )}
                        {sport === 'NFL' && (
                            <Grid item xs={12} sm={2}>
                                <TextField
                                    label="Update All QB Stack Size"
                                    type="number"
                                    value={globalQBStackSize}
                                    onChange={handleGlobalQBStackSizeChange}
                                    inputProps={{ min: 1, max: 4 }}
                                    size="small"
                                    fullWidth
                                />
                            </Grid>
                        )}
                        {sport === 'NFL' && (
                            <Grid item xs={12} sm={2}>
                                <TextField
                                    label="Update All Bring Back Size"
                                    type="number"
                                    value={globalBringBackSize}
                                    onChange={handleGlobalBringBackSizeChange}
                                    inputProps={{ min: 0, max: 3 }}
                                    size="small"
                                    fullWidth
                                />
                            </Grid>
                        )}
                        {sport !== 'NFL' && (
                            <Grid item xs={12} sm={4}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={stackingInfo.useSecondaryStacks}
                                            onChange={(e) => handleStackingChange({ useSecondaryStacks: e.target.checked })}
                                            color="secondary"
                                        />
                                    }
                                    label="Use Secondary Stacks"
                                />
                            </Grid>
                        )}
                    </Grid>
                    <Grid container spacing={2} sx={{ mt: 2 }}>
                        {sport !== 'NFL' && (
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="Primary Stack Size"
                                    type="number"
                                    value={stackingInfo.primaryStackCount ?? ''}
                                    onChange={(e) => {
                                        const value = Math.min(Math.max(1, Number(e.target.value)), 4);
                                        handleStackingChange({ primaryStackCount: value });
                                    }}
                                    disabled={!stackingInfo.usePrimaryStacks}
                                    size="small"
                                    fullWidth
                                    inputProps={{ min: 1, max: 4 }}
                                />
                            </Grid>
                        )}

                        {sport === 'NFL' ? (
                            <Grid item xs={12} sm={6}>

                            </Grid>
                        ) : (
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="Secondary Stack Size"
                                    type="number"
                                    value={stackingInfo.secondaryStackCount ?? ''}
                                    onChange={(e) => handleStackingChange({ secondaryStackCount: e.target.value === '' ? null : Number(e.target.value) })}
                                    disabled={!stackingInfo.useSecondaryStacks}
                                    size="small"
                                    fullWidth
                                />
                            </Grid>
                        )}
                    </Grid>
                </CardContent>
            </Card>

            <Card elevation={3}>
                <CardContent>
                    <TableContainer>
                        <Table size="small" aria-label="stacking table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>{sport === 'NFL' ? "QB Stack" : "Primary"}</TableCell>
                                    {sport !== 'NFL' && <TableCell>Secondary</TableCell>}
                                    <TableCell>Team</TableCell>
                                    {sport === 'NFL' ? (
                                        <>
                                            <TableCell>All Teammates</TableCell>
                                            <TableCell>QB Stack Size</TableCell>
                                            <TableCell>Teammates</TableCell>
                                            <TableCell>Opponent</TableCell>
                                            <TableCell>All Opponents</TableCell>
                                            <TableCell>Bring Back Size</TableCell>
                                            <TableCell>Opponents</TableCell>
                                        </>
                                    ) : (
                                        Array.from({ length: 9 }, (_, i) => (
                                            <TableCell key={`header-mlb-${i + 1}`} align="center">{i + 1}</TableCell>
                                        ))
                                    )}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(sport === 'NFL' ? nflTeamStacks : teamStacks).map((stack, stackIndex) => (
                                    <TableRow key={`team-${stack.team}-${stackIndex}`} sx={{ '&:nth-of-type(odd)': { backgroundColor: 'action.hover' } }}>
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                checked={stack.isPrimaryStack}
                                                onChange={() => handleStackToggle(stackIndex, 'primary')}
                                                disabled={!stackingInfo.usePrimaryStacks}
                                                size="small"
                                            />
                                        </TableCell>
                                        {sport !== 'NFL' && (
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    checked={stack.isSecondaryStack}
                                                    onChange={() => handleStackToggle(stackIndex, 'secondary')}
                                                    disabled={!stackingInfo.useSecondaryStacks}
                                                    size="small"
                                                />
                                            </TableCell>
                                        )}
                                        <TableCell component="th" scope="row" sx={{ typography: 'body2' }}>
                                            {stack.team}
                                        </TableCell>
                                        {sport === 'NFL' ? (
                                            <>
                                                <TableCell padding="checkbox">
                                                    <Checkbox
                                                        checked={(stack as NFLTeamStack).allTeammatesSelected}
                                                        onChange={() => handleAllTeammatesToggle(stackIndex)}
                                                        disabled={!stack.isPrimaryStack}
                                                        size="small"
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <TextField
                                                        type="number"
                                                        value={(stack as NFLTeamStack).qbStackSize}
                                                        onChange={(e) => handleStackSizeChange(stackIndex, 'qbStackSize', parseInt(e.target.value))}
                                                        inputProps={{ min: 1, max: 4 }}
                                                        disabled={!stack.isPrimaryStack}
                                                        size="small"
                                                        sx={{ width: '60px' }}
                                                    />
                                                </TableCell>


                                                <TableCell>
                                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                        {(stack as NFLTeamStack).players
                                                            .filter((player): player is Player =>
                                                                player !== null &&
                                                                player.Proj > 3 &&
                                                                (player.playerPosition || player.Pos) !== 'DST'
                                                            )
                                                            .sort((a, b) =>
                                                                (a.playerPosition || a.Pos) === 'QB' ? -1 :
                                                                    (b.playerPosition || b.Pos) === 'QB' ? 1 : 0
                                                            )
                                                            .map((player, playerIndex) => {
                                                                const position = player.playerPosition || player.Pos || '';
                                                                return (
                                                                    <Chip
                                                                        key={`player-${stack.team}-${player.id}`}
                                                                        label={player.Player}
                                                                        onClick={() => position !== 'QB' && handlePlayerToggle(stackIndex, player.Player, false)}
                                                                        color={position === 'QB' ? "secondary" : (stack as NFLTeamStack).eligibleTeammates.has(player.Player) ? "primary" : "default"}
                                                                        size="small"
                                                                        sx={position === 'QB' ? { pointerEvents: 'none' } : {}}
                                                                        disabled={!stack.isPrimaryStack}
                                                                    />
                                                                );
                                                            })
                                                        }
                                                    </Box>
                                                </TableCell>
                                                <TableCell component="th" scope="row" sx={{ typography: 'body2' }}>
                                                    {(stack as NFLTeamStack).opponent}
                                                </TableCell>
                                                <TableCell padding="checkbox">
                                                    <Checkbox
                                                        checked={(stack as NFLTeamStack).allOpponentsSelected}
                                                        onChange={() => handleAllOpponentsToggle(stackIndex)}
                                                        disabled={!stack.isPrimaryStack}
                                                        size="small"
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <TextField
                                                        type="number"
                                                        value={(stack as NFLTeamStack).bringBackSize}
                                                        onChange={(e) => handleStackSizeChange(stackIndex, 'bringBackSize', parseInt(e.target.value))}
                                                        inputProps={{ min: (stack as NFLTeamStack).eligibleOpponents.size > 0 ? 1 : 0, max: 3 }}
                                                        disabled={!stack.isPrimaryStack || (stack as NFLTeamStack).eligibleOpponents.size === 0}
                                                        size="small"
                                                        sx={{ width: '60px' }}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    {renderOpponents(stack as NFLTeamStack)}
                                                </TableCell>
                                            </>
                                        ) : (
                                            stack.players.map((player, playerIndex) => (
                                                <TableCell
                                                    key={`player-${stack.team}-${playerIndex}`}
                                                    align="center"
                                                    sx={{ typography: 'body2', whiteSpace: 'nowrap' }}
                                                >
                                                    {player ? player.Player : '-'}
                                                </TableCell>
                                            ))
                                        )}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </CardContent>
            </Card>
        </Box>
    );
}

export default Stacking;