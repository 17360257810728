import React from 'react';
import Typography from '@mui/material/Typography';
import { useAuth } from '../context/AuthContext';

function Home() {
    const { isLoggedIn } = useAuth();

    return (
        <div>
            <Typography variant="h4" component="h1" gutterBottom>
                Welcome to Daily Fantasy Optimizer
            </Typography>
            {!isLoggedIn && (
                <Typography variant="body1">
                    Please login to use the app.
                </Typography>
            )}
        </div>
    );
}

export default Home;